import api from 'api';
import axios from 'axios';
import { CARDS, CORE_API } from 'config';
import {
  Card,
  ICategory,
  IRecentCard,
  IRecentCards,
  IResponseCard,
  IResponsePostCardSequence,
  IResponseStartCurriculum,
} from 'interfaces/cards';
import node_api from 'node-api';
import { AttentionDimension } from 'node-api/attention/AttentionApi.types';
import { AttentionClient } from 'node-api/attention/AttentionApiClient';
import { Member } from 'state/reducers/patient';
import AuthService from './auth-service';
import { ILegacyNodeCardDetails, ILegacyNodeCardListEntry } from './card-service.types';
import { rpmEventTrackingServiceInstance } from './rpm/rpm-track-event-service';

abstract class CardsService {
  static async getCard(cardUUID: string): Promise<{ card: IResponseCard } | null> {
    try {
      const response = await axios.get<{ card: IResponseCard }>(
        `${CARDS.contentURL}/content-delivery/v1/cards/${cardUUID}?card.preview=true`,
        {
          headers: {
            authorization: `Bearer ${AuthService.getAuth()?.Authorization.token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  static async getCategories(patientUUID: string): Promise<ICategory[] | null> {
    try {
      const response = await axios.get(
        `${CORE_API.url}/v2/cards/categories/tree/patient/${patientUUID}`,
        {
          headers: {
            authorization: `Bearer ${AuthService.getAuth()?.Authorization.token}`,
          },
        }
      );

      return response.data?.data;
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  static async postCardSequence(
    subCategoryId: string,
    patientUUID: string,
    date: string,
    forced: boolean
  ): Promise<IResponsePostCardSequence | null> {
    const data = {
      provider_id: `${AuthService.getAuth()?.Authorization.id}`,
      date,
      forced,
    };
    const headers = {
      Authorization: `Bearer ${AuthService.getAuth()?.Authorization.token}`,
      'content-type': 'application/json',
    };
    try {
      const response = await axios.post(
        `${CORE_API.url}/v2/cards/subcategory/${subCategoryId}/patient/${patientUUID}`,
        data,
        { headers }
      );

      return response?.data;
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  static async startCurriculum(
    categoryId: string,
    patientUUID: string
  ): Promise<IResponseStartCurriculum | null> {
    const data = {
      provider_id: `${AuthService.getAuth()?.Authorization.id}`,
    };
    const headers = {
      Authorization: `Bearer ${AuthService.getAuth()?.Authorization.token}`,
      'content-type': 'application/json',
    };
    try {
      const response = await axios.post(
        `${CORE_API.url}/v2/cards/category/${categoryId}/patient/${patientUUID}`,
        data,
        { headers }
      );

      return response?.data;
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  static async getRecentCards(userId: number): Promise<Card[] | null> {
    const authorization = AuthService.getAuth()?.Authorization;
    try {
      const response = await axios.get<IRecentCards>(
        `${CARDS.contentURL}/v1/users/${userId}/timeline?recent=true`,
        {
          headers: {
            Authorization: `Bearer ${authorization?.token}`,
            'content-type': 'application/json',
          },
        }
      );

      const { cards, success } = response.data;
      if (!success) {
        return null;
      }

      const parsedCards: Card[] = cards.map((card: IRecentCard) => {
        return {
          id: card.id,
          uuid: card.uuid,
          title: card.title,
          commentsCount: card.comments_count,
          completed: card.completed,
          contentSnapshotUrl: card.content_snapshot_url,
          dueAt: card.due_at,
          createdAt: card.createdAt,
          pinned: card.pinned,
          starred: card.starred,
          taglist: card.taglist,
          updatedAt: card.updated_at,
          tracking: card.tracking,
        };
      });

      return parsedCards;
    } catch (error) {
      console.error('getRecentCards::error: ', error);
    }

    return null;
  }

  static async postComment({
    member,
    card,
    comment,
  }: {
    member: Member;
    card: ILegacyNodeCardListEntry;
    comment: string;
  }): Promise<void> {
    await api().post(`timeline/${card.uuid}/comments`, {
      auth_token: 'AUTH_TOKEN',
      content: comment,
    });

    // Important to track RPM associated with sending card comments and reviewed photo
    rpmEventTrackingServiceInstance.sendCardCommentEvent(member.uuid, card.card_id);
    rpmEventTrackingServiceInstance.sendProviderReviewingPictureEvent(member.uuid);

    // Mark member as tended to for this card comment
    CardsService.markAsReviewed({ member, card });
  }

  static async markAsReviewed({
    member,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    card: _, // TODO: Mark a specific card as reviewed (need backend support)
  }: {
    member: Member;
    card: ILegacyNodeCardListEntry;
  }): Promise<void> {
    // Mark member as tended to for this card comment
    const attentionClient = new AttentionClient();
    attentionClient.markMemberAsTendedToForDimension({
      memberIds: [Number(member.id)],
      dimensionKey: AttentionDimension.CardComments,
    });
  }

  static async getLegacyCardListing(
    memberId: number,
    month: string,
    onlyMe: boolean
  ): Promise<ILegacyNodeCardListEntry[]> {
    const source = axios.CancelToken.source();
    const params = {
      patient_id: memberId,
      month,
      all_cards: onlyMe ? 0 : 1,
      auth_token: 'AUTH_TOKEN',
    };
    const {
      data: { data },
    } = await node_api().get<{
      data: { card_count: string; cards: ILegacyNodeCardListEntry[] };
    }>('patientcards', {
      cancelToken: source.token,
      params,
    });
    return data.cards;
  }

  static async getLegacyCardContentAndComments(cardId: number): Promise<ILegacyNodeCardDetails> {
    const source = axios.CancelToken.source();
    const params = { card_id: cardId, auth_token: 'AUTH_TOKEN' };
    const {
      data: { data },
    } = await node_api().get<{ data: ILegacyNodeCardDetails }>('card', {
      cancelToken: source.token,
      params,
    });
    return { ...data, comments: data.comments || [] };
  }
}

export default CardsService;

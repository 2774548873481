import {
  ProviderTimeTrackerClient,
  RpmProviderEvent,
} from 'node-api/rpm/ProviderTimeTrackerClient';
import { RPMTrack, RpmInitParams } from './rpm-track-event.types';

export class RpmEventTrackingService {
  static providerUuid: string;

  static providerRole: string;

  static setDefaultValues(defaults: RpmInitParams) {
    RpmEventTrackingService.providerUuid = defaults.providerUuid;
    RpmEventTrackingService.providerRole = defaults.role!;
  }

  readonly timeTrackerClient: ProviderTimeTrackerClient;

  constructor() {
    this.timeTrackerClient = new ProviderTimeTrackerClient();
  }

  private createRpmData(memberUuid: string, measureName: string) {
    return {
      memberUuid,
      providerUuid: RpmEventTrackingService.providerUuid,
      measureName,
      time: Date.now(),
      providerRole: RpmEventTrackingService.providerRole,
    };
  }

  async sendVisitProfileEvent(memberUuid: string) {
    const params = this.createRpmData(memberUuid, RpmProviderEvent.ProviderVisitMemberProfile);

    try {
      await this.timeTrackerClient.track(params);
    } catch (err) {
      console.error(
        `[RpmEventTrackingService::sendVisitProfileEvent] problems sending ${RpmProviderEvent.ProviderVisitMemberProfile} `,
        err
      );
    }
  }

  async sendProviderReviewingPictureEvent(memberUuid: string) {
    const params = this.createRpmData(memberUuid, RpmProviderEvent.ProviderReviewPicture);

    try {
      await this.timeTrackerClient.track(params);
    } catch (err) {
      console.error(
        `[RpmEventTrackingService::sendProviderReviewingPictureEvent] problems sending ${RpmProviderEvent.ProviderReviewPicture} `,
        err
      );
    }
  }

  async sendCardEvent(memberUuid: string) {
    const params = this.createRpmData(memberUuid, RpmProviderEvent.ProviderSendCard);

    try {
      await this.timeTrackerClient.track(params);
    } catch (err) {
      console.error(
        `[RpmEventTrackingService::sendCardEvent] problems sending ${RpmProviderEvent.ProviderSendCard} `,
        err
      );
    }
  }

  async sendCardCommentEvent(memberUuid: string, cardId: number) {
    const params = this.createRpmData(memberUuid, RpmProviderEvent.ProviderSendCardComment);

    const rpmData = { ...params, referenceId: cardId };
    try {
      await this.timeTrackerClient.track(rpmData);
    } catch (err) {
      console.error(
        `[RpmEventTrackingService::sendCardCommentEvent] problems sending ${RpmProviderEvent.ProviderSendCardComment} `,
        err
      );
    }
  }

  async sendAddWeightEvent(memberUuid: string) {
    const params = this.createRpmData(memberUuid, RpmProviderEvent.ProviderProfileAddWeight);

    try {
      await this.timeTrackerClient.track(params);
    } catch (err) {
      console.error(
        `[RpmEventTrackingService::sendAddWeightEvent] problems sending ${RpmProviderEvent.ProviderProfileAddWeight} `,
        err
      );
    }
  }

  async getRPMEvents(patientUuid: string, limit: number, offset: number) {
    return this.timeTrackerClient.list({ patientUuid, limit, offset });
  }

  async track(data: RPMTrack, extraRelatedValue?: number) {
    const params = this.createRpmData(data.memberUuid, data.measureName);

    const rpmData = extraRelatedValue ? { ...params, referenceId: extraRelatedValue } : params;
    try {
      await this.timeTrackerClient.track(rpmData);
    } catch (err) {
      console.error(`[RpmEventTrackingService::track] problems sending ${data.measureName} `, err);
    }
  }
}

export const rpmEventTrackingServiceInstance = new RpmEventTrackingService();

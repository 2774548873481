/* eslint-disable */
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import Autocomplete from '@material-ui/lab/Autocomplete';
import api from 'api';
import axios from 'axios';
import { Button, Loading, Tag } from 'components/elements';
import Switch from 'components/elements/switch';
import { Box, Section } from 'components/general-styles';
import Icon from 'components/icon';
import email from 'components/icon/pngs/email.png';
import ghost from 'components/icon/pngs/ghost3-96.png';
import medicalIcon from 'components/icon/pngs/medical-id-50.png';
import phone from 'components/icon/pngs/phone.png';
import Toast from 'components/toast';
import { APP_VERSION_PROVIDERS, INBODY_DASHBOARD } from 'config';
import { ReviewListSlugs } from 'config/reviewLists';
import { markMemberAsReviewed } from 'controllers/review/review-controller';
import useLastProgram from 'hooks/useLastProgram';
import { CommonEventNames } from 'lib/types';
import 'mailgo/dist/mailgo.min.js';
import moment from 'moment-timezone';
import { schedulingV3Client } from 'node-api/scheduleV3/client.v3';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { TrackableElement } from 'services/mixpanel/mixpanel-service';
import {
  ChangeElementTypes,
  ClickElementTypes,
  EventHandlerNames,
} from 'services/mixpanel/mixpanel.types';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';
import { ClickableElement } from 'services/mixpanel/shared-mixpanel-service';
import { ConfirmationContext } from 'state/contexts/confirmation';
import { EventContext } from 'state/contexts/event';
import { PatientContext } from 'state/contexts/patient';
import { PatientSummaryContext } from 'state/contexts/patientSummary';
import { PatientTagsContext } from 'state/contexts/patientTags';
import { ProgramContext } from 'state/contexts/program';
import { ProvidersContext } from 'state/contexts/providers';
import { ReviewLists4Context } from 'state/contexts/reviewLists4';
import { StatusContext } from 'state/contexts/status';
import { StickyContext } from 'state/contexts/sticky';
import { TagContext } from 'state/contexts/tag';
import { TaskContext } from 'state/contexts/task';
import { UserContext } from 'state/contexts/user';
import { ActionTypes as ConfirmationActionTypes } from 'state/reducers/confirmation';
// TODO: Stop using ActionTypes everywhere. Use EventActionTypes instead
import { Stack, Typography } from '@mui/material';
import { ROLE_UNAVAILABLE } from 'globalConstants';
import { getPreferenceByName } from 'helpers/members';
import { EFeatures } from 'lib/feature/features.types';
import MembersClient from 'node-api/members/MembersClient';
import { Preferences } from 'node-api/members/member.types';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { FeaturesContext } from 'state/contexts/features';
import { ActionTypes as EventActionTypes } from 'state/reducers/event';
import { ActionTypes as PatientActionTypes, Provider } from 'state/reducers/patient';
import { Tag as EnaraTag, ActionTypes as PatientTagsActionTypes } from 'state/reducers/patientTags';
import { ActionTypes as ProgramActionTypes } from 'state/reducers/program';
import { ActionTypes } from 'state/reducers/providers';
import { ActionTypes as StickyActionTypes } from 'state/reducers/sticky';
import { ActionTypes as TagActionTypes } from 'state/reducers/tag';
import { ActionTypes as TaskActionTypes } from 'state/reducers/task';
import Theme from 'styles/theme';
import {
  debug,
  getAppVersion,
  getCurrentProviderState,
  isCurrentProviderHidden,
} from 'utils/helpers';
import AuthService from '../../../../services/auth-service';
import {
  Actions,
  Avatar,
  AvatarWrapper,
  ClinicName,
  Content,
  CurrentProgramContent,
  CurrentProgramName,
  Dob,
  Email,
  Ghost,
  GlucoseMonitoring,
  GlucoseMonitoringSwitch,
  Item,
  Link,
  Media,
  Name,
  Phone,
  Profile,
  Providers,
  Record,
  Relations,
  Stats,
  StatusWrapper,
  Tags,
  TagsContent,
  Top,
} from './style';
import './style.css';

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    font: "17px/19px 'Proxima Nova Rg'",

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Theme.white,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: Theme.white,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: Theme.white,
    },
    '& .MuiAutocomplete-popupIndicatorOpen': {
      transform: 'rotate(0deg)',
    },
    '& .MuiIconButton-root': {
      color: Theme.teal,
    },
  },
  input: {
    '&::placeholder': {
      color: Theme.teal,
      opacity: 1,
    },
    color: Theme.teal,
  },
}));

type Props = {
  hasReview: boolean;
  listLabel: string;
  listName: string;
  patientId: number;
};

type MemberSummary = {
  age: number;
  avatar: string;
  birthdate: string;
  clinic_name: string;
  email: string;
  first_name: string;
  gender: string;
  id: number;
  last_appointment: { provider_id: number; time: string };
  last_interaction: string;
  last_name: string;
  logged_food: string;
  logged_weight: string;
  next_appointment: { provider_id: number; time: string };
  phone: string;
  providers: Provider[];
  status: string;
};
type Status = { id: number; text: string };

const MARK_AS_REVIEW_TOOLTIP_FOR_CHAT = [
  "Click 'Mark as Reviewed' to clear new messages from both",
  'private patient chats and care team mentions. To clear only one,',
  "use the 'Mark as Read' toggle instead.",
].join('<br />');
const MARK_AS_REVIEW_TOOLTIPS_BY_SLUG: { [key: string]: string } = {
  [ReviewListSlugs.PrivateMessages]: MARK_AS_REVIEW_TOOLTIP_FOR_CHAT,
  [ReviewListSlugs.MentionedInTeamChat]: MARK_AS_REVIEW_TOOLTIP_FOR_CHAT,
};

const SummaryBox: FC<Props> = ({ patientId, listLabel, listName, hasReview }) => {
  const { patientState, patientDispatch } = useContext(PatientContext);
  const { providersState, providersDispatch, getProviderById } = useContext(ProvidersContext);
  const { userState } = useContext(UserContext);
  const [currentProviderState] = useState(getCurrentProviderState(userState, providersState));
  const isCurProviderHidden = isCurrentProviderHidden(currentProviderState);
  const { statusState } = useContext(StatusContext);
  const { taskDispatch } = useContext(TaskContext);
  const { stickyDispatch } = useContext(StickyContext);
  const { eventDispatch } = useContext(EventContext);
  const { programDispatch } = useContext(ProgramContext);
  const { tagDispatch } = useContext(TagContext);
  const { patientTagsState, patientTagsDispatch } = useContext(PatientTagsContext);
  const { patientSummaryState } = useContext(PatientSummaryContext);
  const { confirmationDispatch } = useContext(ConfirmationContext);
  const { reviewLists4State } = useContext(ReviewLists4Context);
  const { featuresState } = useContext(FeaturesContext);

  const [open, setOpen] = React.useState(false);

  const patient = patientState.current!;
  const [summary, setSummary] = useState<MemberSummary>();
  const [tags, setTags] = useState<EnaraTag[]>([]);
  const [reviewed, setReviewed] = useState(patient?.reviewed);
  const [glucoseMonitoringEnabled, setGlucoseMonitoringEnabled] = useState(
    patient.diabetic === true
  );
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [tagsLoading, setTagsLoading] = useState(true);
  const [calling, setCalling] = useState(false);
  const [isEnabledCallingButton, setIsEnabledCallingButton] = useState(true);
  const [reviewing, setReviewing] = useState(false);
  const [statuses] = useState<Status[]>(statusState.data as Status[]);
  const [status, setStatus] = useState<string | Status>('');
  const [assignedProviders, setAssignedProviders] = useState<Provider[]>([]);
  const [videoCallPreferenceEnabled, setVideoCallPreferenceEnabled] = useState(false);

  const videoCallPreference = getPreferenceByName(
    patientState.current?.preferences ?? [],
    Preferences.UseNewVideoCall
  );

  const currentProgram = useLastProgram();

  const classes = useStyles();

  const updateStatus = async (status: { id: number }) => {
    const status_id = status.id;

    try {
      await api().put(`/patients/${patient.uuid}`, {
        auth_token: 'AUTH_TOKEN',
        profile_status_id: status_id,
      });
    } catch (err) {
      debug(err as string);
    }
  };

  const ImageExist = (url: string) => {
    const img = new Image();
    img.src = url;
    return img.height !== 0;
  };

  const switchOnChange = async () => {
    const newValue = !glucoseMonitoringEnabled;
    try {
      const { data } = await api().put(`/patients/${patient.uuid}`, {
        auth_token: 'AUTH_TOKEN',
        diabetic: newValue,
      });

      if (data.success !== 'true') {
        throw new Error();
      } else setGlucoseMonitoringEnabled(newValue);
    } catch (err) {
      Toast.show('error', 'Failed to update the Glucose Monitoring flag!');
    }
  };

  const switchOnUseNewVideo = async () => {
    // At first we set the new state from the UI

    const memberClient = new MembersClient();
    const source = axios.CancelToken.source();
    const memberId = Number(patient.id);

    setVideoCallPreferenceEnabled(!videoCallPreferenceEnabled);
    const updated = await memberClient.updateMemberPreference(
      memberId,
      videoCallPreference!.preference.id,
      source.token
    );

    if (updated) {
      // At this point we use the state from the database after the update in the BE

      const memberPreferences = await memberClient.getMemberPreferences(memberId);
      if (memberPreferences) {
        patientDispatch({
          type: PatientActionTypes.SET_PREFERENCES,
          payload: { preferences: memberPreferences },
        });
      }

      MixpanelWrapperInstance.track(EventNames.MemberProfileNewVideoCall, {
        targetLabel: 'Member Profile',
        targetType: ClickableElement.BUTTON,
        memberUuid: patient.uuid,
        memberName: `${patient.first_name} ${patient.last_name}`,
        providerUuid: userState.uuid,
        source: 'video-call-status',
      });
    }
  };

  const toggleStickyModal = () => {
    // TODO: 'payload: undefined' should be removed from this action
    stickyDispatch({ type: StickyActionTypes.ToggleModal, payload: undefined });
  };
  const toggleEventModal = () => {
    eventDispatch({ type: EventActionTypes.ToggleModal, payload: undefined });
  };

  const toggleTaskModal = () => {
    taskDispatch({ type: TaskActionTypes.ToggleModal, payload: undefined });
  };

  const toggleProgramModal = () => {
    programDispatch({ type: ProgramActionTypes.TOGGLE_MODAL, payload: undefined });
  };

  const toggleTagModal = () => {
    tagDispatch({ type: TagActionTypes.ToggleModal, payload: undefined });
  };

  const toggleProvidersModel = () => {
    providersDispatch({ type: ActionTypes.TOGGLE_MODAL, payload: undefined });
  };

  const getTooltipText = (data: Provider) => {
    const name = `${data.first_name} ${data.last_name}`;
    const roles = data.role_names?.join(' - ') || ROLE_UNAVAILABLE;
    return name + ': ' + roles;
  };

  const getTooltip = (provider: Provider | Provider[]) => {
    if (Array.isArray(provider)) {
      return provider.reduce((acc, p, i, a) => {
        const tag = a.length === i + 1 ? '' : '<br />';
        return `${acc} ${getTooltipText(p)} ${tag}`;
      }, '');
    }
    return getTooltipText(provider);
  };

  const getProvider = (id: number) => {
    const provider = getProviderById(id);
    return (
      provider && (
        <h6>
          With {provider.first_name} {provider.last_name}
        </h6>
      )
    );
  };

  const handleCall = async () => {
    setCalling(true);

    try {
      setIsEnabledCallingButton(false);

      MixpanelWrapperInstance.track(EventNames.MemberCallPatient, {
        targetLabel: 'Call Patient',
        targetType: ClickableElement.BUTTON,
        targetLocation: 'Member Summary',
        memberUuid: patient.uuid,
        memberName: `${patient.first_name} ${patient.last_name}`,
        providerUuid: userState.uuid,
        source: 'call-patient',
      });

      const meetingUrl = await schedulingV3Client.getProviderVideoUrl(
        {
          id: Number(patient.id),
          uuid: patient.uuid,
        },
        { id: userState.id ?? -1, uuid: userState.uuid ?? '' },
        'provider',
        'call-patient'
      );

      window.open(meetingUrl, '_blank');
    } catch (err) {
      debug(err as string);
    }

    setIsEnabledCallingButton(true);
    setCalling(false);
  };

  const handleInbodyDashboard = () => {
    const auth = AuthService.getAuth()!;
    const providerToken = auth.Authorization.token;

    const queryParameters = `memberId=${patient.id}&authToken=${providerToken}`;
    window.open(`${INBODY_DASHBOARD.url}?${queryParameters}`, '_blank');
  };

  const handleReview = async () => {
    setReviewing(true);

    try {
      MixpanelWrapperInstance.track(EventNames.ReviewListsClickMarkAsReviewed, {
        targetLabel: 'Mark as reviewed',
        targetType: ClickableElement.BUTTON,
        targetLocation: 'Member profile',
        List: listLabel,
      });

      await markMemberAsReviewed(
        userState,
        patient,
        listName as ReviewListSlugs,
        reviewLists4State.data
      );

      patientDispatch({
        type: PatientActionTypes.REVIEW,
        payload: {
          id: Number(patient.id),
        },
      });

      setReviewed(true);
    } catch (err) {
      debug(err as string);
      Toast.show('error', "Couldn't mark this patient as reviewed");
    }

    setReviewing(false);
  };

  // ----------- Confirmation -------------
  const onYes = async (onClose: () => void, id: string) => {
    // remove the tag from the patient profile
    try {
      const {
        data: { data },
      } = await api().delete(`patients/${patient.uuid}/patient_tags/${id}?auth_token=AUTH_TOKEN`);

      patientTagsDispatch({
        type: PatientTagsActionTypes.Set,
        payload: {
          patientId: Number(patient.id),
          data,
        },
      });
    } catch (err) {
      debug(`Error ${err}`);
    }
    onClose();
  };

  const onNo = async (onClose: () => void) => {
    onClose();
  };

  const handleTagDelete = async (tagId: number, tagName: string) => {
    confirmationDispatch({
      type: ConfirmationActionTypes.SHOW,
      payload: {
        onYes,
        onNo,
        id: tagId,
        message: `Are you sure you want to remove the tag |${tagName}| from the patient profile?`,
      },
    });
  };

  const groupByProvider = useCallback(() => {
    const assignedProvidersLength = assignedProviders.length;
    const numberOfIcons = 4;
    return (
      <ul>
        {assignedProviders.slice(0, numberOfIcons).map((provider) => (
          <li
            data-tip={getTooltip(provider)}
            data-for={`provider-${provider.id}`}
            key={`provider-${provider.id}`}>
            <ReactTooltip id={`provider-${provider.id}`} place='top' type='dark' effect='float' />
            {provider.avatar ? (
              <img src={provider.avatar} alt={`${provider.first_name} ${provider.last_name}`} />
            ) : (
              <Icon icon='userBubble' color='turquoise' size={35} />
            )}
          </li>
        ))}
        {assignedProvidersLength > numberOfIcons && (
          <li
            data-tip={getTooltip(assignedProviders.slice(numberOfIcons, assignedProvidersLength))}
            data-for='provider-else'
            key='provider-else'>
            <ReactTooltip id='provider-else' place='top' type='dark' effect='float' multiline />
            <span>{`+${assignedProvidersLength - numberOfIcons}`}</span>
          </li>
        )}
        <li
          data-tip={
            'Assign and un-assign providers to the patient profile. <br />' +
            'Providers must be assigned to view profiles and access the schedule tab for booking appointments. <br />' +
            'Assign only one provider per role (Medical Provider, Nutrition Specialist, Exercise Specialist). <br />' +
            "For secondary providers, use the 'Content Editor' role."
          }
          data-for='provider-add-remove'
          key='provider-add-remove'>
          <ReactTooltip id='provider-add-remove' place='top' type='dark' effect='float' multiline />
          <TrackableElement
            customEventName={CommonEventNames.ROLE_ASSIGNMENT}
            name='+'
            type={ClickElementTypes.BUTTON}
            eventHandlerName={EventHandlerNames.ONCLICK}>
            <Button color='white' onClick={toggleProvidersModel}>
              <Icon icon='plus' background='grayDark' color='white' size={16} />
            </Button>
          </TrackableElement>
        </li>
      </ul>
    );
  }, [assignedProviders]);

  useEffect(() => {
    if (summary?.providers?.length) {
      let _assignedProviders: Provider[] = [];
      for (const p of summary.providers) {
        let assignedProvider = _assignedProviders.find(
          (assignedProvider) => assignedProvider.id === p.id
        );
        const role_name = p.role?.display_value || p.role?.name || ROLE_UNAVAILABLE;
        if (assignedProvider) {
          assignedProvider.role_names.push(role_name);
        } else {
          assignedProvider = { ...p, role_names: [role_name] };
          _assignedProviders.push(assignedProvider);
        }
      }
      setAssignedProviders(_assignedProviders);
      patientDispatch({
        type: PatientActionTypes.SET_ASSIGNED_PROVIDERS,
        payload: { patientId, providers: _assignedProviders },
      });
    }
  }, [summary?.providers]);

  useEffect(() => {
    // TODO: This is weird... it's using status to set itself
    // TODO: this looks like it's not just a string... must fix it soon
    setStatus(statusState.data[status as any] as string);
  }, [statusState.data]);

  useEffect(() => {
    setTags(patientTagsState.data[patientId]);
  }, [patientTagsState.data]);

  useEffect(() => {
    setSummaryLoading(true);
    const source = axios.CancelToken.source();
    // Get summary data
    const getSummaryData = async () => {
      try {
        const { data } = await api().get(`stats/patient/${patient.id}/AUTH_TOKEN`, {
          cancelToken: source.token,
        });

        setSummary(data);
        setSummaryLoading(false);
      } catch (err) {
        if (!axios.isCancel(err)) {
          debug(err as string);
          setSummaryLoading(false);
        }
      }
    };

    setTagsLoading(true);
    setTags([]);
    // Get patient tags
    const getTagsData = async () => {
      try {
        const {
          data: { data },
        } = await api().get(`patients/${patient.uuid}/patient_tags?auth_token=AUTH_TOKEN`, {
          cancelToken: source.token,
        });

        // setTags(data);
        setTagsLoading(false);

        patientTagsDispatch({
          type: PatientTagsActionTypes.Set,
          payload: {
            patientId,
            data,
          },
        });
      } catch (err) {
        if (!axios.isCancel(err)) {
          debug(err as string);
          setTagsLoading(false);
        }
      }
    };

    const getPreferences = async () => {
      const memberId = Number(patient.id);
      const memberPreferences = await new MembersClient().getMemberPreferences(memberId);
      if (memberPreferences) {
        const preferenceFound = getPreferenceByName(memberPreferences, Preferences.UseNewVideoCall);
        if (preferenceFound) {
          setVideoCallPreferenceEnabled(preferenceFound.value);
        }

        patientDispatch({
          type: PatientActionTypes.SET_PREFERENCES,
          payload: { preferences: memberPreferences },
        });
      }
    };

    if (patient) {
      getSummaryData();
      getTagsData();
      getPreferences();
    }

    return () => {
      source.cancel();
    };
  }, [patientId, patientSummaryState.reload]);

  if (summaryLoading || tagsLoading) return <Loading />;

  return (
    <Section>
      {summary && (
        <Box>
          <Actions>
            <StatusWrapper>
              <TrackableElement
                name='Member Status'
                type={ChangeElementTypes.DROPDOWN}
                eventHandlerName={EventHandlerNames.ONCHANGE}>
                <Autocomplete
                  value={status as Status}
                  disableClearable
                  id='edit-status'
                  style={{ width: 162 }}
                  classes={classes}
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  onChange={(event, s) => {
                    if (s?.id) {
                      updateStatus(s);
                      setStatus(s.text);
                      summary.status = s.text;
                    }
                  }}
                  getOptionSelected={(option, value) => option.text === value.text}
                  getOptionLabel={(option) => option.text}
                  options={statuses.map((key) => ({
                    ...key,
                    text: `${key.text}`,
                  }))}
                  noOptionsText="Couldn't retrieve statuses"
                  popupIcon={<EditIcon />}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={summary.status ?? 'New Status'}
                      variant='outlined'
                      style={{ marginTop: -9, marginBottom: -9 }} // FB: Hack until we replace this with simpler buttons/style.
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                  renderOption={(s) => {
                    return (
                      <Record key={`status-${s.id}`}>
                        <Item>{s.text}</Item>
                      </Record>
                    );
                  }}
                />
              </TrackableElement>
            </StatusWrapper>

            {currentProgram?.value ? (
              <CurrentProgramContent>
                <CurrentProgramName
                  data-tip={`Program: ${currentProgram.displayValue}<br/>Starting: ${moment(
                    currentProgram.startDate
                  ).format('YYYY-MM-DD')}`}
                  data-for='current-program'
                  key='current-program'>
                  <ReactTooltip id='current-program' place='top' type='dark' effect='float' html />
                  {currentProgram.displayValue}
                </CurrentProgramName>
                <TrackableElement
                  name='Manage Programs'
                  type={ClickElementTypes.BUTTON}
                  eventHandlerName={EventHandlerNames.ONCLICK}>
                  <div
                    data-tip='Manage Programs'
                    data-for='manage-programs'
                    key='manage-programs'
                    onClick={toggleProgramModal}>
                    <ReactTooltip id='manage-programs' place='top' type='dark' effect='float' />
                    <Icon icon='map' size={18} />
                  </div>
                </TrackableElement>
              </CurrentProgramContent>
            ) : (
              <TrackableElement
                name='Manage Programs'
                type={ClickElementTypes.BUTTON}
                eventHandlerName={EventHandlerNames.ONCLICK}>
                <Button color='white' onClick={toggleProgramModal}>
                  <Icon icon='map' size={18} />
                  Manage Programs
                </Button>
              </TrackableElement>
            )}

            <TrackableElement
              name='New Event'
              type={ClickElementTypes.BUTTON}
              eventHandlerName={EventHandlerNames.ONCLICK}>
              <Button color='white' onClick={toggleEventModal}>
                <Icon icon='event' size={18} />
                New Event
              </Button>
            </TrackableElement>

            <TrackableElement
              name='Create Sticky'
              type={ClickElementTypes.BUTTON}
              eventHandlerName={EventHandlerNames.ONCLICK}>
              <Button color='white' onClick={toggleStickyModal}>
                <Icon icon='event' size={18} />
                Create Sticky
              </Button>
            </TrackableElement>

            <TrackableElement
              name='Create Task'
              type={ClickElementTypes.BUTTON}
              eventHandlerName={EventHandlerNames.ONCLICK}>
              <Button color='white' onClick={toggleTaskModal}>
                <Icon icon='suitcase' size={18} />
                Create Task
              </Button>
            </TrackableElement>

            {hasReview && (
              <div
                data-tip={MARK_AS_REVIEW_TOOLTIPS_BY_SLUG[listName]}
                data-for='mark-as-review-messages'>
                <ReactTooltip
                  id='mark-as-review-messages'
                  place='top'
                  type='dark'
                  effect='float'
                  multiline
                />
                <Button
                  onClick={handleReview}
                  submitting={reviewing}
                  disabled={reviewed}
                  color={Theme.salmon}>
                  Mark as reviewed
                </Button>
              </div>
            )}
          </Actions>
          <ClinicName>
            <span>Clinic: </span>
            {summary.clinic_name || patient.clinic_name || 'N/A'}
          </ClinicName>
          <Profile>
            <Media>
              <AvatarWrapper>
                <div>
                  {summary.avatar && ImageExist(summary.avatar) ? (
                    <img src={summary.avatar} alt={`${summary.first_name} ${summary.last_name}`} />
                  ) : (
                    <Avatar>
                      <Icon icon='user' size={136} color='teal' />
                    </Avatar>
                  )}
                </div>
                {isCurProviderHidden && (
                  <Ghost>
                    <img src={ghost} />
                  </Ghost>
                )}
              </AvatarWrapper>
              <Button
                onClick={handleCall}
                disabled={isCurProviderHidden || !isEnabledCallingButton}
                submitting={calling}>
                Call Patient
              </Button>
              <TrackableElement
                name='Inbody Dashboard'
                type={ClickElementTypes.BUTTON}
                eventHandlerName={EventHandlerNames.ONCLICK}>
                <Button onClick={handleInbodyDashboard}>Inbody Dashboard</Button>
              </TrackableElement>
            </Media>
            <Content>
              <Top>
                <Name>
                  <p>{`${summary.first_name} ${summary.last_name}`}</p>
                  <Dob>
                    {`${moment(summary.birthdate).format('MMMM Do YYYY')} `}
                    <span>(age {summary.age}), </span>
                    {summary.gender}
                  </Dob>
                  {summary.email && (
                    <div>
                      <img src={email} alt='Email' />
                      <Email className='mailtoui' target='_blank' href={`mailto:${summary.email}`}>
                        {`${summary.email}`}
                      </Email>
                    </div>
                  )}
                  {summary.phone && (
                    <div>
                      <img src={phone} alt='Phone' />
                      <Phone
                        className='mailgo'
                        data-tel={summary.phone}
                        href={`callto:${summary.phone}`}>
                        {`${summary.phone}`}
                      </Phone>
                    </div>
                  )}
                  {summary.id && (
                    <div>
                      <img src={medicalIcon} />
                      <span>{`Member ID: ${summary.id}`}</span>
                    </div>
                  )}
                </Name>
                {featuresState?.[EFeatures.ProviderProfileEnableDisableNewVideoCall] && (
                  <Stack>
                    <Typography component='h5'>Use new video call</Typography>
                    <Switch
                      checked={videoCallPreferenceEnabled}
                      onChange={switchOnUseNewVideo}
                      value={videoCallPreferenceEnabled}
                    />
                  </Stack>
                )}
              </Top>
              <Relations>
                <Providers>
                  <div>
                    <h5>Providers:</h5>
                  </div>
                  {groupByProvider()}
                </Providers>
                <GlucoseMonitoring
                  data-tip={glucoseMonitoringEnabled ? 'Enabled' : 'Disabled'}
                  data-for='glucose-monitoring'
                  key='glucose-monitoring'>
                  <ReactTooltip id='glucose-monitoring' place='top' type='dark' effect='float' />

                  <div>
                    <h5>Glucose Monitoring:</h5>
                  </div>
                  <GlucoseMonitoringSwitch>
                    <Switch
                      checked={glucoseMonitoringEnabled === true}
                      onChange={switchOnChange}
                      value={glucoseMonitoringEnabled}
                    />
                  </GlucoseMonitoringSwitch>
                </GlucoseMonitoring>
                <Tags>
                  <div>
                    <h5>Tags:</h5>
                  </div>
                  <TagsContent>
                    {tags.map((tag) => (
                      <Tag
                        key={`tag-${tag.id}`}
                        backgroundColor='turquoise'
                        color='teal'
                        onClose={() => handleTagDelete(tag.id, tag.name)}>
                        {tag.name}
                      </Tag>
                    ))}
                    <Link
                      style={{ marginTop: '5px' }}
                      title='Add a tag to a patient profile'
                      onClick={toggleTagModal}>
                      Add Tag
                    </Link>
                  </TagsContent>
                </Tags>
              </Relations>

              <Stats>
                <div>
                  <h5>Last seen</h5>
                  <p>
                    {summary.last_interaction
                      ? moment(summary.last_interaction).fromNow()
                      : 'Never'}
                  </p>
                </div>
                <div>
                  <h5>Logged food</h5>
                  <p>{summary.logged_food ? moment(summary.logged_food).fromNow() : 'Never'}</p>
                </div>
                <div>
                  <h5>Logged Weight</h5>
                  <p>{summary.logged_weight ? moment(summary.logged_weight).fromNow() : 'Never'}</p>
                </div>

                <div>
                  <h5>Last appointment</h5>
                  {summary.last_appointment.time ? (
                    <>
                      {getProvider(summary.last_appointment.provider_id)}
                      <p>{moment(summary.last_appointment.time).fromNow()}</p>
                    </>
                  ) : (
                    <p>Never</p>
                  )}
                </div>

                <div>
                  <h5>Next appointment</h5>
                  {summary.next_appointment.time ? (
                    <>
                      {getProvider(summary.next_appointment.provider_id)}
                      <p>{moment(summary.next_appointment.time).fromNow()}</p>
                    </>
                  ) : (
                    <p>Not scheduled</p>
                  )}
                </div>
                {APP_VERSION_PROVIDERS.includes(
                  `${userState.first_name}${userState.last_name}`
                ) && (
                  <div>
                    <h5>App Version</h5>
                    <p>{getAppVersion(patient.app_version)}</p>
                  </div>
                )}
              </Stats>
            </Content>
          </Profile>
        </Box>
      )}
      {hasReview && !summary && (
        <div
          data-tip={MARK_AS_REVIEW_TOOLTIPS_BY_SLUG[listName]}
          data-for='mark-as-review-messages-bottom'>
          <ReactTooltip
            id='mark-as-review-messages-bottom'
            place='top'
            type='dark'
            effect='float'
            multiline
          />
          <Button
            onClick={handleReview}
            submitting={reviewing}
            disabled={reviewed}
            color={Theme.salmon}>
            Mark as reviewed
          </Button>
        </div>
      )}
    </Section>
  );
};

export default SummaryBox;

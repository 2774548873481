import {
  QueryBuilder as ClockIcon,
  FmdGood as LocationIcon,
  OpenInNew as OpenInNewIcon,
} from '@mui/icons-material';
import { Avatar, Box, Grid, IconButton, Typography } from '@mui/material';
import LoadingButton from 'components/atoms/wrappers/LoadingButton';
import { convertSummaryStatusToReadableString } from 'components/elements/notes/note/NotePreview/format';
import { SELECTED_NOTE_FOR_PREVIEW_SUMMARY_ID_KEY } from 'globalConstants';
import { DateFormats, replaceUtcStringFromDateByZeroOffset } from 'helpers/date';
import momentTimezone from 'moment-timezone';
import { schedulingV3Client } from 'node-api/scheduleV3/client.v3';
import { AppointmentSummary } from 'node-api/scheduleV3/types.v3';
import { EntityWithSummaryInfo } from 'node-api/scribe/scribe.types';
import { FC, useContext, useState } from 'react';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';
import { ClickableElement } from 'services/mixpanel/shared-mixpanel-service';
import { ClinicContext } from 'state/contexts/clinic';
import { UserContext } from 'state/contexts/user';
import Theme from 'styles/theme';
import { debug, saveOnLocalStorage } from 'utils/helpers';
import { DEFAULT_TIMEZONE } from 'utils/timezones';
import { Date, DayOfWeek, PatientName, Time } from './style';

type Props = {
  appointment: EntityWithSummaryInfo<AppointmentSummary>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentTime: any;
  displayNotes: boolean;
  isPastAppointment: boolean;
  onDisplayMember: (patient: { uuid: string }) => void;
};

const ScheduleAppointmentCard: FC<Props> = ({
  appointment,
  currentTime,
  displayNotes,
  isPastAppointment,
  onDisplayMember,
}) => {
  const [calling, setCalling] = useState<boolean>(false);

  const { userState } = useContext(UserContext);
  const { clinicState } = useContext(ClinicContext);

  const { patient, provider, startAt, meetingStatus, meetingId } = appointment;

  const startAtWithTimezone = momentTimezone(
    replaceUtcStringFromDateByZeroOffset(startAt),
    DateFormats.STANDARD_FORMATTING_FOR_RAW_DATES_FORMAT
  ).tz(DEFAULT_TIMEZONE);

  const handleCallPatient = async () => {
    setCalling(true);

    MixpanelWrapperInstance.track(EventNames.MemberCallJoinAppointmentSummary, {
      targetLabel: 'Join Call',
      targetType: ClickableElement.BUTTON,
      targetLocation: 'Home',
      memberUuid: patient.uuid,
      memberName: `${patient.firstName} ${patient.lastName}`,
      providerUuid: userState.uuid,
      source: 'appointment-summary',
    });

    try {
      const url = await schedulingV3Client.getProviderVideoUrl(
        { id: patient.id, uuid: patient.uuid },
        {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: userState.id!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          uuid: userState.uuid!,
        },
        'provider',
        'appointment-summary'
      );

      window.open(url, '_blank');
    } catch (err) {
      debug(err as string);
    }

    setCalling(false);
  };

  const handleViewNote = () => {
    saveOnLocalStorage(SELECTED_NOTE_FOR_PREVIEW_SUMMARY_ID_KEY, { meetingId, startAt }, true);

    window.open(`/members/${patient.uuid}/notes`, '_blank');
  };

  return (
    <Box className='active-sessions__schedule-appointment-card'>
      <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Grid item xs={1}>
          <Box className='date'>
            <DayOfWeek>{startAtWithTimezone.format('ddd')}</DayOfWeek>
            <Date>{startAtWithTimezone.format('DD')}</Date>

            <Typography variant='h6'>{startAtWithTimezone.format('MMM')}</Typography>
          </Box>
        </Grid>

        <Grid item xs={1}>
          <Box display='flex' gap={1}>
            <ClockIcon fontSize='small' color='disabled' />
            <Time>{startAtWithTimezone.format('hh:mm A')}</Time>
          </Box>
          <Box display='flex' gap={1}>
            <LocationIcon fontSize='small' color='disabled' />
            <Typography>Online</Typography>
          </Box>
        </Grid>

        <Grid item xs={1.5}>
          <Box display='flex' gap={2}>
            <Avatar
              sx={{ width: 45, height: 45, bgcolor: Theme.salmon }}
              src={provider.avatar}
              alt={`${patient.firstName}`}
              onClick={() => onDisplayMember(patient)}
            />

            <Box display='flex' flexDirection='column'>
              <Typography color={Theme.gray}>Patient</Typography>
              <PatientName onClick={() => onDisplayMember(patient)}>
                <Typography>
                  {patient.firstName} {patient.lastName}
                </Typography>
              </PatientName>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={1.5}>
          <Box display='flex' gap={2}>
            <Avatar
              sx={{ width: 45, height: 45, bgcolor: Theme.bubbleBackground }}
              src={provider.avatar}
              alt={`${provider.firstName}`}
            />
            <Box display='flex' flexDirection='column'>
              <Typography color={Theme.gray}>Provider</Typography>
              <Typography>
                {provider.firstName} {provider.lastName}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={1}>
          <Box display='flex' flexDirection='column' alignItems='flex-start'>
            <Typography color={Theme.gray}>Clinic</Typography>
            <Typography>
              {clinicState.clinics.find((c) => c.id === patient.clinicId)?.name || 'Unknown'}
            </Typography>
          </Box>
        </Grid>

        {displayNotes && (
          <Grid item xs={1.5}>
            <Box display='flex' flexDirection='column' alignItems='flex-start'>
              <Typography color={Theme.gray}>Notes</Typography>
              {meetingStatus ? (
                <Box display='flex' alignItems='center'>
                  <Typography color={Theme.black}>
                    {convertSummaryStatusToReadableString(meetingStatus || 'unknown')}
                  </Typography>
                  <IconButton onClick={handleViewNote}>
                    <OpenInNewIcon fontSize='small' color='primary' />
                  </IconButton>
                </Box>
              ) : (
                <Typography>
                  {convertSummaryStatusToReadableString(meetingStatus || 'unknown')}
                </Typography>
              )}
            </Box>
          </Grid>
        )}

        {!isPastAppointment && (
          <Grid item xs={1}>
            <LoadingButton
              onClick={handleCallPatient}
              loading={calling}
              disabled={!currentTime.isSame(startAtWithTimezone, 'd')}
              variant='contained'
              size='small'>
              Join call
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ScheduleAppointmentCard;

import { AppointmentSummary } from './AppointmentSummary/AppointmentSummary';
import PatientsReviewLists from './member-review-lists/MemberReviewLists';
import PatientsHighLevelStats from './patients-high-level-stats';

const Dashboard = ({ history }) => (
  <div style={{ paddingLeft: 20, paddingRight: 20 }}>
    <PatientsHighLevelStats history={history} />
    <PatientsReviewLists history={history} />
    <AppointmentSummary history={history} />
  </div>
);

Dashboard.propTypes = {};

export default Dashboard;

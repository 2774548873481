import api from 'api';
import axios from 'axios';
import { MainSection, MainSectionContainer } from 'components/general-styles';
import ProfileTabsBox from 'components/main/profile/ProfileTabsBox/ProfileTabsBox';
import EventNotes from 'components/main/profile/event-notes';
import StickyNotes from 'components/main/profile/sticky-notes';
import SummaryBox from 'components/main/profile/summary-box/SummaryBox';
import Toast from 'components/toast';
import { SEND_GRID_MISSED_APPOINTMENTS_PARAM } from 'globalConstants';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { ClickElementTypes } from 'services/mixpanel/mixpanel.types';
import {
  RpmEventTrackingService,
  rpmEventTrackingServiceInstance,
} from 'services/rpm/rpm-track-event-service';
import { PatientContext } from 'state/contexts/patient';
import { ProviderContext } from 'state/contexts/provider';
import { UserContext } from 'state/contexts/user';
import { ActionTypes as PatientActionTypes } from 'state/reducers/patient';
import { ActionTypes as ProviderActionTypes } from 'state/reducers/provider';
import {
  debug,
  getCurrentProviderState,
  isCurrentProviderHidden,
  isFromSendGridMissedAppointments,
} from 'utils/helpers';
import { getTimezoneByClinicId } from 'utils/timezones';
import Chat from '../profile/chat/Chat';
import { getUserInfo } from '../rpm-event/util';

const PatientPage = ({ match, location }) => {
  const { providerDispatch } = useContext(ProviderContext);
  const { patientState, patientDispatch } = useContext(PatientContext);
  const { userState } = useContext(UserContext);

  const [patient, setPatient] = React.useState();
  const [loaded, setLoaded] = React.useState(false);

  const [user] = useState(getUserInfo(userState));
  const [fromSendGrid] = useState(isFromSendGridMissedAppointments(location.search));

  useEffect(() => {
    const source = axios.CancelToken.source();
    const source2 = axios.CancelToken.source();
    const { id: uuid } = match.params;

    const currentRoleFound = userState.original_user.available_logins.find((login) => {
      return login.uuid === userState.uuid;
    });

    const roleName = currentRoleFound
      ? `${currentRoleFound.first_name} ${currentRoleFound.last_name}`
      : userState.clinical_role?.name;

    RpmEventTrackingService.setDefaultValues({
      providerUuid: userState.original_user.uuid, // always sending the provider uuid
      role: roleName ?? 'unknown',
    });

    const getPatient = async () => {
      try {
        const { data } = await api().get(`/patients/${uuid}?auth_token=AUTH_TOKEN`, {
          cancelToken: source.token,
          params: {
            provider_uuid: userState.uuid,
          },
        });

        // TODO: Member timezone should be returned from BE, setting it for now based on the clinic ID
        data.patient.timezone = getTimezoneByClinicId(data.patient.clinic_id);

        patientDispatch({
          type: PatientActionTypes.SET,
          payload: {
            patients: [data.patient],
          },
        });

        setLoaded(true);
      } catch (err) {
        if (!axios.isCancel(err)) {
          debug(err);
        }
      }
    };

    if (uuid) {
      getPatient();
      rpmEventTrackingServiceInstance.sendVisitProfileEvent(uuid);
    }

    return () => {
      patientDispatch({ type: PatientActionTypes.CLEAR });
      source.cancel();
      source2.cancel();
    };
  }, [match]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getProviders = async () => {
      const selectedPatient = patientState.current;
      if (!selectedPatient) {
        return;
      }

      try {
        const { data } = await api().get(`users/${selectedPatient.id}/providers/AUTH_TOKEN`, {
          cancelToken: source.token,
        });

        if (isCurrentProviderHidden(getCurrentProviderState(userState, data))) {
          Toast.show('error', `You are not allowed to view this patient profile!`);
          return;
        }

        providerDispatch({
          type: ProviderActionTypes.SET,
          payload: {
            providers: data,
          },
        });

        setPatient(selectedPatient);
      } catch (err) {
        if (!axios.isCancel(err)) {
          debug(err);
        }
      }
    };

    if (loaded) {
      getProviders();
    }

    return () => {
      source.cancel();
    };
  }, [patientState, loaded]);

  useEffect(() => {
    if (fromSendGrid && !window.history.state?.[SEND_GRID_MISSED_APPOINTMENTS_PARAM.name]) {
      debug('>>> Track click on member link at Sendgrid into Mixpanel');
      window.history.replaceState(
        { ...(window.history.state || {}), [SEND_GRID_MISSED_APPOINTMENTS_PARAM.name]: true },
        ''
      );
      MixpanelWrapperInstance.trackClick({
        name: 'Member link on the missed appointments template at SendGrid',
        type: ClickElementTypes.LINK,
        source: 'SendGrid template',
      });
    }
  }, [fromSendGrid]);

  return (
    <MainSectionContainer>
      {patient && loaded && (
        <>
          <MainSection>
            <StickyNotes patientId={patient.id} key={`sticky-${patient.id}`} />

            <EventNotes
              patientUuid={patient.uuid}
              patientId={patient.id}
              key={`event-${patient.id}`}
            />

            <SummaryBox patientId={patient.id} key={`summary-${patient.id}`} />
            <ProfileTabsBox patient={patient} user={user} />
          </MainSection>

          <Chat patientId={patient.id} key={`chat-${patient.id}`} />
        </>
      )}
    </MainSectionContainer>
  );
};

PatientPage.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default PatientPage;

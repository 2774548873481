import { Box, MenuItem, Select, Typography } from '@mui/material';
import { getAge } from 'helpers/date';
import { MedicalProblem } from 'node-api/scribe/medical';
import {
  MeetingContextContentType,
  MeetingContextLocation,
  MeetingSummaryStage,
} from 'node-api/scribe/scribe.types';
import { SectionProps } from './render-sections';

export const MeetingContextSection = ({
  content,
  stage,
  handlers,
}: SectionProps<MeetingContextContentType>) => {
  const renderMainProblemSelector = () => {
    return (
      <Select
        disabled={stage === MeetingSummaryStage.PreVisit}
        id='meeting-context-problem-select'
        size='small'
        sx={{ fontSize: '14px', borderRadius: '40px', height: '1.5rem' }}
        value={content.data.mainProblem}
        inputProps={{
          id: 'diagnosis-duration-select-input',
        }}
        onChange={(v) => {
          handlers.handleChangeMeetingContext({
            ...content,
            data: {
              ...content.data,
              mainProblem: v.target.value as MedicalProblem,
            },
          });
        }}>
        {Object.entries(MedicalProblem).map(([problemKey, problemName]) => (
          <MenuItem key={`context-${problemKey}`} value={problemName}>
            {problemName}
          </MenuItem>
        ))}
      </Select>
    );
  };

  return (
    <Box mb={3}>
      <Typography textAlign='justify'>
        {content.data.patientName} is a {getAge(content.data.patientDOB)} year old{' '}
        {content.data.patientGender} who is here for an Initial Evaluation and Assessment for{' '}
        {renderMainProblemSelector()}. This visit was conducted {content.data.appointmentLocation}.{' '}
        {content.data.appointmentLocation === MeetingContextLocation.Telehealth && (
          <>
            The patient gave informed consent for telehealth during registration, including rights,
            services, and privacy measures, willingly participating in the virtual consultation.
          </>
        )}
      </Typography>
    </Box>
  );
};

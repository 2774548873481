import axios from 'axios';
import { CORE_API } from '../config';

type CoreProviderConfig = {
  provider: {
    id: number;
    email: string;
    scribe_config: {
      view_notes: boolean;
      view_transcripts?: boolean;
      generate_notes: boolean;
    };
  };
  scheduling: {
    view_recording: boolean;
  };
};

type ProviderScribeConfig = {
  viewNotes: boolean;
  viewTranscripts?: boolean;
  generateNotes: boolean;
};

type ProviderConfig = {
  provider: {
    id: number;
    email: string;
    scribeConfig: ProviderScribeConfig;
  };
  scheduling: {
    viewRecording: boolean;
  };
};

type SignInProps = {
  phone: string;
  password: string;
};

type ResetPasswordProps = {
  email: string;
};

type SetPasswordProps = {
  password: string;
  password_confirmation: string;
  auth_token: string;
};

type SetAuthProps = {
  auth_token: string;
  user_id: number;
};

export type AuthProps = {
  Authorization: {
    id: number;
    token: string;
  };
};

export const AUTHORIZATION = 'Authorization';

const AuthService = {
  signIn(payload: SignInProps) {
    return axios.post(`${CORE_API.url}/v1/auth`, payload);
  },

  resetPassword(payload: ResetPasswordProps) {
    return axios.post(`${CORE_API.url}/v1/auth/send_reset_email`, payload);
  },

  setPassword(payload: SetPasswordProps) {
    return axios.post(`${CORE_API.url}/v1/profile/change_password`, payload);
  },

  // Auth Data
  removeAuth() {
    localStorage.removeItem(AUTHORIZATION);
  },

  setAuth(payload: SetAuthProps) {
    localStorage.setItem(
      AUTHORIZATION,
      JSON.stringify({
        id: payload.user_id,
        auth_token: payload.auth_token,
      })
    );
  },

  getAuth(): AuthProps | undefined {
    if (localStorage.getItem(AUTHORIZATION) === 'undefined') {
      localStorage.removeItem(AUTHORIZATION);
      return undefined;
    }

    const Authorization = JSON.parse(localStorage.getItem(AUTHORIZATION) || '{}');

    if (!Authorization) {
      return undefined;
    }

    return {
      Authorization: {
        id: Authorization.id,
        token: Authorization.auth_token,
      },
    };
  },

  async getConfig(): Promise<ProviderConfig | null> {
    const auth = AuthService.getAuth();
    const token = auth?.Authorization.token || '';

    try {
      const response = await axios.get<CoreProviderConfig>(
        `${CORE_API.url}/v4/providers/me/config`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const { provider, scheduling } = response.data;

      return {
        provider: {
          id: provider.id,
          email: provider.email,
          scribeConfig: {
            viewNotes: provider.scribe_config.view_notes,
            viewTranscripts: provider.scribe_config.view_transcripts,
            generateNotes: provider.scribe_config.generate_notes,
          },
        },
        scheduling: {
          viewRecording: scheduling.view_recording,
        },
      };
    } catch (error) {
      // Unauthorized or empty token, we don't care about this
      return null;
    }
  },
};

export default AuthService;

import { NoteDetail, NoteDetailWithTranscription } from 'node-api/scribe/scribe.types';

const timeStringIntoSeconds = (timeString: string) => {
  const timeSections = timeString.split(':');
  let multiplier = 1;
  let totalSeconds = 0;

  for (let i = timeSections.length - 1; i > -1; i--) {
    totalSeconds += Number(timeSections[i]) * multiplier;
    multiplier *= 60;
  }

  return totalSeconds;
};

export const expandNoteTranscription = (note: NoteDetail): NoteDetailWithTranscription => {
  if (!note.meetingTranscriptionDialog) {
    return note as NoteDetailWithTranscription;
  }

  const { lines } = note.meetingTranscriptionDialog;
  const timeRanges: [number, number][] = [];

  for (let index = 0; index < lines.length; index++) {
    const lineTime = timeStringIntoSeconds(lines[index][0]);
    const nextLineTime = lines[index + 1]
      ? timeStringIntoSeconds(lines[index + 1][0])
      : Number.POSITIVE_INFINITY;
    timeRanges.push([lineTime, Math.max(lineTime, nextLineTime - 1)]);
  }

  return {
    ...note,
    meetingTranscriptionDialog: {
      ...note.meetingTranscriptionDialog,
      timeRanges,
    },
  };
};

export const isCloseInTime = ({
  currentTime,
  fromTime,
  untilTime,
}: {
  currentTime: number;
  fromTime: number;
  untilTime: number;
}) => {
  return currentTime >= fromTime && currentTime <= untilTime;
};

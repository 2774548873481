import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Section } from 'components/general-styles';
import { CalendarView } from 'components/main/appointments/CalendarView';
import { VIEW_MODE_LOCAL_STORAGE_KEY } from 'globalConstants';
import { FC, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';
import { ClickableElement } from 'services/mixpanel/shared-mixpanel-service';
import { getItemFromLocalStorage, saveOnLocalStorage } from 'utils/helpers';
import ActiveSessions from './ActiveSessions';
import './AppointmentSummary.css';

type ViewMode = 'active-sessions' | 'calendar-view';

export const AppointmentSummary: FC<RouteComponentProps> = ({ history }) => {
  const [mode, setMode] = useState<ViewMode>(
    getItemFromLocalStorage(VIEW_MODE_LOCAL_STORAGE_KEY, false) || 'active-sessions'
  );

  const handleToggleMode = () => {
    const targetMode: ViewMode = mode === 'active-sessions' ? 'calendar-view' : 'active-sessions';

    saveOnLocalStorage(VIEW_MODE_LOCAL_STORAGE_KEY, targetMode, false);

    MixpanelWrapperInstance.track(EventNames.HomeAppointmentsChangeDisplayMode, {
      targetType: ClickableElement.TAB,
      targetLocation: 'Home',
      targetValue: targetMode,
      targetOldValue: mode,
    });

    setMode(targetMode);
  };

  const handleDisplayMember = (member: { uuid: string }) => {
    if (member?.uuid) {
      history.push(`/members/${member.uuid}`);
    }
  };

  return (
    <Section>
      <Tabs value={mode} onChange={handleToggleMode}>
        <Tab label='Appointment Summary' value='active-sessions' />
        <Tab label='Calendar View' value='calendar-view' />
      </Tabs>

      {mode === 'active-sessions' ? (
        <ActiveSessions onDisplayMember={handleDisplayMember} />
      ) : (
        <CalendarView />
      )}
    </Section>
  );
};

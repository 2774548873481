import { FilterAltOff } from '@mui/icons-material';
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
} from '@mui/material';
import { convertSummaryStatusToReadableString } from 'components/elements/notes/note/NotePreview/format';
import { FILTER_BY_NO_CLINIC_ID, FILTER_BY_NO_MEETING_STATUS } from 'globalConstants';
import { MeetingSummaryStatus } from 'node-api/scribe/scribe.types';
import { FC, useContext } from 'react';
import { ClinicContext } from 'state/contexts/clinic';

export type FiltersChangedArgs = {
  clinicId: number;
  meetingStatus: MeetingSummaryStatus | typeof FILTER_BY_NO_MEETING_STATUS;
};

export const DEFAULT_FILTERS: FiltersChangedArgs = {
  clinicId: FILTER_BY_NO_CLINIC_ID,
  meetingStatus: FILTER_BY_NO_MEETING_STATUS,
};

type Props = {
  displayNotes: boolean;
  selectedFilters: FiltersChangedArgs;
  onFiltersChanged: (args: Partial<FiltersChangedArgs>) => void;
};

const CalendarFiltersToolbar: FC<Props> = ({ displayNotes, selectedFilters, onFiltersChanged }) => {
  const {
    clinicState: { clinicsAssignedToProvider },
  } = useContext(ClinicContext);

  const handleChangeClinic = (e: SelectChangeEvent): void => {
    onFiltersChanged({ clinicId: parseInt(e.target.value) });
  };

  const handleChangeMeetingStatus = (e: SelectChangeEvent): void => {
    onFiltersChanged({ meetingStatus: e.target.value as MeetingSummaryStatus });
  };

  const handleClearFilters = (): void => {
    onFiltersChanged(DEFAULT_FILTERS);
  };

  return (
    <Stack
      sx={{
        width: '100%',
        marginTop: 2,
        marginBottom: 2,
        padding: 0,
        justifyContent: 'end',
      }}
      direction='row'
      gap={1}>
      <FormControl sx={{ flex: 0.15 }}>
        <InputLabel>Clinics</InputLabel>
        <Select label='Clinics' value={`${selectedFilters.clinicId}`} onChange={handleChangeClinic}>
          {[
            ...clinicsAssignedToProvider.map((clinic, index) => (
              <MenuItem value={clinic.id} key={`${clinic.id}-${index}`}>
                {clinic.name}
              </MenuItem>
            )),
            <MenuItem key='clinics-all' value={FILTER_BY_NO_CLINIC_ID}>
              All
            </MenuItem>,
          ]}
        </Select>
      </FormControl>

      {displayNotes && (
        <FormControl sx={{ flex: 0.15 }}>
          <InputLabel>Notes Status</InputLabel>
          <Select
            label='Notes Status'
            value={`${selectedFilters.meetingStatus}`}
            onChange={handleChangeMeetingStatus}>
            {[
              ...Object.values(MeetingSummaryStatus).map((status, index) => (
                <MenuItem value={status} key={`${status}-${index}`}>
                  {convertSummaryStatusToReadableString(status)}
                </MenuItem>
              )),
              <MenuItem key='note-status-all' value={FILTER_BY_NO_MEETING_STATUS}>
                All
              </MenuItem>,
            ]}
          </Select>
        </FormControl>
      )}

      <Tooltip title='Clear Filters' placement='top'>
        <IconButton aria-label='Clear Filters' onClick={handleClearFilters}>
          <FilterAltOff />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default CalendarFiltersToolbar;

import { IRole } from 'components/main/rpm-event/rpm.types';

export type Patient = {
  id: number;
  uuid: string;
  clinic_id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  last_timezone: string;
};

export type Task = {
  assignee: string;
  assigned_to_id: number;
  assigned_to_name: string;
  completed_by: string | null;
  completed_by_id: number | null;
  description: string;
  date_assigned: string;
  due_date: string | null;
  date_updated: string;
  id: number;
  start_date: string;
  status: boolean;
};

export type User = {
  fullName: string;
  role: string;
  roles: IRole[];
};

export enum MemberMainTabs {
  AGREEMENTS = 'Agreements',
  PROFILE = 'Profile',
  ASSESSMENTS = 'Assessments',
  PICTURES = 'Pictures',
  CARDS = 'Cards',
  SCHEDULE = 'Schedule',
  CALENDAR = 'Calendar',
  APPOINTMENTS = 'Appointments',
  RPM_EVENTS = 'RPM Events',
  NOTES = 'Notes',
}

export enum CommonEventNames {
  ROLE_ASSIGNMENT = 'Clicked Role Assignment',
}

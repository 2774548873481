import { EventClickArg, EventContentArg, EventInput, EventSourceFuncArg } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import dayGridPlugin from '@fullcalendar/daygrid';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import Popover from 'components/elements/popover/Popover';
import { Box } from 'components/general-styles';
import Toast from 'components/toast';
import {
  CALENDAR_TIMEZONE_LOCAL_STORAGE_KEY,
  FILTER_BY_NO_CLINIC_ID,
  FILTER_BY_NO_MEETING_STATUS,
} from 'globalConstants';
import { ALLOWED_TIMEZONES, LOCAL_TIMEZONE } from 'lib/timezone';
import AppointmentsClient, { ScheduledAppointment } from 'node-api/schedule/AppointmentsClient';
import { fillEntityOutWithSummaryInfo } from 'node-api/scribe/helpers';
import { EntityWithSummaryInfo } from 'node-api/scribe/scribe.types';
import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import AuthService from 'services/auth-service';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';
import { ClickableElement } from 'services/mixpanel/shared-mixpanel-service';
import { UserContext } from 'state/contexts/user';
import { getAppointmentTypeCodes } from 'utils/schedule';
import './CalendarView.css';
import CalendarFiltersToolbar, {
  DEFAULT_FILTERS,
  FiltersChangedArgs,
} from './components/CalendarFiltersToolbar';
import { SchedulePreviewCard } from './components/SchedulePreviewCard';

const appointmentsClient = new AppointmentsClient();

function parseAppointmentToCalendarEvent(
  appointment: EntityWithSummaryInfo<ScheduledAppointment>
): EventInput {
  const memberFullName = [appointment.user.firstName, appointment.user.lastName].join(' ');

  return {
    // Base properties required by the package
    id: `${appointment.id}`,
    title: memberFullName,
    start: new Date(appointment.startAt),
    end: new Date(appointment.endAt),
    // Extended props
    extendedProps: appointment,
  };
}

function renderEventContent(eventInfo: EventContentArg, selectedEvent: EventImpl | null) {
  const { event, timeText } = eventInfo;
  const appointment = event.extendedProps as EntityWithSummaryInfo<ScheduledAppointment>;
  const types = getAppointmentTypeCodes(appointment.appointmentType);
  return (
    <div
      className={[
        'event-content',
        `intention-${types[0]}`,
        `specialty-${types[1]}`,
        `location-${types[2]}`,
        selectedEvent?.id === event.id ? 'selected' : '',
      ].join(' ')}>
      <span className='event-member'>{event.title}</span>
      <span className='event-time'>
        {timeText} <b>{types[2]}</b>
      </span>
    </div>
  );
}

export const CalendarView: FC<RouteProps> = () => {
  const [displayNotes, setDisplayNotes] = useState<boolean>(false);
  const [selectedEventElement, setSelectedEventElement] = useState<HTMLElement | null>(null);
  const [selectedEventObject, setSelectedEventObject] = useState<EventImpl | null>(null);
  const [timezone, setTimezone] = useState(
    LOCAL_TIMEZONE ||
      ALLOWED_TIMEZONES.find(
        (tz) => tz.value === localStorage.getItem(CALENDAR_TIMEZONE_LOCAL_STORAGE_KEY)
      ) ||
      ALLOWED_TIMEZONES[0]
  );
  const [calendarViewSeed, setCalendarViewSeed] = useState<number>();

  const filtersRef = useRef<FiltersChangedArgs>(DEFAULT_FILTERS);

  const { userState } = useContext(UserContext);

  const timezoneButtonLabel = `Timezone: ${timezone.description}`;

  const fetchNewEvents = useCallback(
    async (
      fetchInfo: EventSourceFuncArg,
      successCallback: (eventInputs: EventInput[]) => void,
      failureCallback: (error: Error) => void
    ) => {
      if (!userState?.id) {
        return;
      }

      const providerId = userState.id;

      try {
        const { appointments } = await appointmentsClient.getScheduledAppointmentsForProvider(
          providerId,
          {
            endDate: fetchInfo.end,
            startDate: fetchInfo.start,
            ...(filtersRef.current.clinicId !== FILTER_BY_NO_CLINIC_ID && {
              clinicId: filtersRef.current.clinicId,
            }),
          }
        );

        if (appointments?.length === 0) {
          return successCallback([]);
        }

        const appointmentsWithMeetingStatus = await fillEntityOutWithSummaryInfo(appointments);

        // TODO: Meeting status is something we must collect in the BE so we neither have to:
        //  1) fetch status and populate appointments in the FE nor
        //  2) have to do the filtering manually in the FE
        if (filtersRef.current.meetingStatus !== FILTER_BY_NO_MEETING_STATUS) {
          successCallback(
            appointmentsWithMeetingStatus
              .filter((a) => a.meetingStatus === filtersRef.current.meetingStatus)
              .map(parseAppointmentToCalendarEvent)
          );
        } else {
          successCallback(appointmentsWithMeetingStatus.map(parseAppointmentToCalendarEvent));
        }
      } catch (error) {
        failureCallback(error as Error);

        Toast.show('error', 'Could not get scheduled appointments');
      }
    },
    []
  );

  const handleChangeFilters = (args: Partial<FiltersChangedArgs>): void => {
    const prev = filtersRef.current;

    const clinicId = args.clinicId ?? prev.clinicId;
    const meetingStatus = args.meetingStatus ?? prev.meetingStatus;

    if (prev.clinicId !== clinicId || prev.meetingStatus !== meetingStatus) {
      setCalendarViewSeed(Date.now());

      filtersRef.current = { clinicId, meetingStatus };
    }
  };

  const handleRotateTimezone = () => {
    MixpanelWrapperInstance.track(EventNames.WeeklyViewRotateTimezone, {
      targetLabel: timezoneButtonLabel,
      targetType: ClickableElement.BUTTON,
      source: 'weekly-view-toolbar',
    });

    const currentIndex = ALLOWED_TIMEZONES.findIndex((tz) => tz.value === timezone.value);
    const nextIndex = currentIndex + 1 >= ALLOWED_TIMEZONES.length ? 0 : currentIndex + 1;

    // Store the new timezone in local storage
    localStorage.setItem(CALENDAR_TIMEZONE_LOCAL_STORAGE_KEY, ALLOWED_TIMEZONES[nextIndex].value);
    setTimezone(ALLOWED_TIMEZONES[nextIndex]);
  };

  const handleClickEvent = (arg: EventClickArg) => {
    MixpanelWrapperInstance.track(EventNames.WeeklyViewPreviewEvent, {
      targetLabel: 'weekly-view-entry',
      targetType: ClickableElement.ENTRY,
      source: 'weekly-view',
    });

    setSelectedEventElement(arg.el);
    setSelectedEventObject(arg.event);
  };

  const handleClosePreviewCard = () => {
    MixpanelWrapperInstance.track(EventNames.ModalClose, {
      targetLabel: 'no-label',
      targetType: ClickableElement.BACKGROUND,
      source: 'weekly-view-preview-card',
    });

    setSelectedEventElement(null);
    setSelectedEventObject(null);
  };

  useEffect(() => {
    const resolveViewNotesStatus = async () => {
      const config = await AuthService.getConfig();
      if (config && config.provider.scribeConfig.viewNotes) {
        setDisplayNotes(true);
      }
    };

    resolveViewNotesStatus();
  }, []);

  return (
    <div className='calendar-container'>
      <Box className='calendar-box'>
        <CalendarFiltersToolbar
          displayNotes={displayNotes}
          selectedFilters={filtersRef.current}
          onFiltersChanged={handleChangeFilters}
        />

        <FullCalendar
          // Hack that forces the calendar to re-render when any of the filters or timezone change
          // As per the timezone. Please, see https://github.com/fullcalendar/fullcalendar/issues/5753
          key={`calendar-view-${calendarViewSeed}-${timezone.value}`}
          // Styling
          eventClassNames={['calendar-event']}
          viewClassNames={['calendar-view']}
          // Main
          headerToolbar={{
            left: 'today prev,next',
            center: 'title',
            right: 'dayGridMonth timeGridWeek timezone',
          }}
          buttonText={{ today: 'Today' }}
          plugins={[dayGridPlugin, timeGridPlugin, momentTimezonePlugin]}
          timeZone={timezone.value}
          initialView='timeGridWeek'
          // content
          dayMaxEventRows
          events={fetchNewEvents}
          eventContent={(content) => renderEventContent(content, selectedEventObject)}
          // timegrid display
          height='calc(100vh - 160px)'
          allDaySlot={false}
          slotDuration='00:30:00'
          slotLabelInterval='01:00'
          slotMinTime='06:00'
          slotMaxTime='23:00'
          // Interaction
          eventClick={handleClickEvent}
          // Custom actions
          customButtons={{
            timezone: {
              text: timezoneButtonLabel,
              click: handleRotateTimezone,
            },
          }}
          nowIndicator
        />
        <Popover
          anchorEl={selectedEventElement}
          anchorOrigin={{ vertical: 'bottom', horizontal: 20 }}
          onClose={handleClosePreviewCard}>
          {selectedEventObject?.extendedProps && (
            <SchedulePreviewCard
              appointment={
                selectedEventObject.extendedProps as EntityWithSummaryInfo<ScheduledAppointment>
              }
              displayNotes={displayNotes}
              timezone={timezone.value}
            />
          )}
        </Popover>
      </Box>
    </div>
  );
};

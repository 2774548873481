import api from 'api';
import { PICTURES_REVIEW_LIST_NAMES, REVIEW_LIST_ACKNOWLEDGE_MESSAGES } from 'config';
import { ReviewListSlugs } from 'config/reviewLists';
import { AttentionDimension } from 'node-api/attention/AttentionApi.types';
import { AttentionClient } from 'node-api/attention/AttentionApiClient';
import { ReviewListClient } from 'node-api/reviewList/reviewlistClient';
import { rpmEventTrackingServiceInstance } from 'services/rpm/rpm-track-event-service';
import { Member } from 'state/reducers/patient';
import { InitialStateType } from 'state/reducers/reviewLists4';
import { State as UserState } from 'state/reducers/user';
import { getOriginalUserFullName } from 'utils/helpers';
import { REVIEW_LIST_ATTENTION_DIMENSIONS } from './constants';

export const isReviewList4 = (
  reviewList: ReviewListSlugs,
  knownReviewLists4: InitialStateType['data']
): boolean => {
  return (
    !!reviewList &&
    knownReviewLists4?.findIndex((reviewListElement) => reviewListElement.slug === reviewList) >= 0
  );
};

export const markMemberAsReviewed = async (
  userState: UserState,
  member: Member,
  reviewListSlug: ReviewListSlugs,
  knownReviewLists: InitialStateType['data']
) => {
  const promises = [];

  const dimensionKey: AttentionDimension | null =
    REVIEW_LIST_ATTENTION_DIMENSIONS[reviewListSlug] || null;
  if (dimensionKey) {
    const attentionClient = new AttentionClient();
    promises.push(
      attentionClient.markMemberAsTendedToForDimension({
        memberIds: [Number(member.id)],
        dimensionKey,
      })
    );
  }

  if (isReviewList4(reviewListSlug, knownReviewLists)) {
    promises.push(
      new ReviewListClient().reviewMemberInReviewList4({
        reviewListName: reviewListSlug,
        memberId: Number(member.id),
      })
    );
  } else {
    const originalUserFullName = getOriginalUserFullName();

    promises.push(
      api().post('/reviews/AUTH_TOKEN', {
        list_name: reviewListSlug,
        patient_id: Number(member.id),
        ...(userState.isOriginalUser === false &&
          originalUserFullName !== null && { original_user: originalUserFullName }),
      })
    );

    if (reviewListSlug === PICTURES_REVIEW_LIST_NAMES[0]) {
      promises.push(rpmEventTrackingServiceInstance.sendProviderReviewingPictureEvent(member.uuid));
    }

    // acknowledging direct messages
    if (REVIEW_LIST_ACKNOWLEDGE_MESSAGES.includes(reviewListSlug)) {
      promises.push(
        api().post(`providers/${userState.uuid}/provider_acknowledgements?auth_token=AUTH_TOKEN`, {
          auth_token: 'AUTH_TOKEN',
          patient_id: Number(member.id),
        })
      );
    }
  }

  await Promise.all(promises);
};

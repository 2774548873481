import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Box, Drawer, IconButton } from '@mui/material';
import { ChatBox } from 'components/main/profile/chat/room/chatbox/ChatBox';
import { DIRECT_ROOM, TEAM_ROOM } from 'globalConstants';
import { ILegacyMyPanelResponseEntry } from 'node-api/panel/MyPanelLegacyClient.types';
import { FC } from 'react';
import { getDrawerWidth } from '../panelHelpers';

export type PanelQuickAction = 'collapsed' | 'direct-chat';

type Props = {
  quickAction: PanelQuickAction;
  setQuickAction: (action: PanelQuickAction) => unknown;
  selectedMembers: ILegacyMyPanelResponseEntry[];
};

export type PanelActionDrawerRef = {
  toggleDrawerOpen: () => void;
};

export const PanelActionDrawer: FC<Props> = ({ selectedMembers, quickAction, setQuickAction }) => {
  return (
    <>
      <Drawer
        anchor='right'
        open={quickAction === 'collapsed'}
        hideBackdrop
        variant='persistent'
        elevation={1}>
        <IconButton
          aria-label='Expand profile drawer'
          onClick={() => setQuickAction('direct-chat')}>
          <MenuOpenIcon />
        </IconButton>
      </Drawer>
      <Drawer
        anchor='right'
        open={quickAction === 'direct-chat'}
        hideBackdrop
        variant='persistent'
        elevation={2}
        onClose={() => setQuickAction('collapsed')}>
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            alignItems: 'flex-start',
            maxWidth: getDrawerWidth(quickAction),
            minWidth: getDrawerWidth(quickAction),
          }}>
          <IconButton
            sx={{ transform: 'rotate(180deg)' }}
            aria-label='Collapse profile drawer'
            onClick={() => setQuickAction('collapsed')}>
            <MenuOpenIcon />
          </IconButton>
          {selectedMembers.length === 1 && selectedMembers[0] ? (
            <Box
              sx={{
                display: 'flex',
                padding: '5px',
                gap: '5px',
                flexGrow: 1,
                width: '100%',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  flexDirection: 'column',
                  height: 'calc(100vh - 64px)',
                }}>
                <ChatBox
                  roomType={TEAM_ROOM}
                  key={`room-${selectedMembers[0].id}-${DIRECT_ROOM}`}
                />
              </Box>
              {/* <PanelProfilePreview selectedMembers={selectedMembers} /> */}
            </Box>
          ) : (
            <Box>{selectedMembers.length} members selected</Box>
          )}
        </Box>
      </Drawer>
    </>
  );
};

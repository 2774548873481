import { Box, Button, Typography } from '@mui/material';
import { MeetingTranscriptionDialogWithTimeRanges } from 'node-api/scribe/scribe.types';
import { useEffect, useRef } from 'react';
import { isCloseInTime } from '../lib/transcriptions';
import './transcription.css';

interface TranscriptionProps {
  isPlaying: boolean;
  currentTime: number;
  transcription: MeetingTranscriptionDialogWithTimeRanges;
  onClickTextLine: (time: number) => void;
}

const Transcription = ({
  isPlaying,
  currentTime,
  transcription,
  onClickTextLine,
}: TranscriptionProps) => {
  const dialogLineRef = useRef<HTMLButtonElement | null>(null);

  const resolveSpeaker = (label: string) => {
    if (transcription.patientConfig.token === label) {
      return { name: transcription.patientConfig.value, kind: 'patient' };
    }

    return { name: transcription.providerConfig.value, kind: 'provider' };
  };

  useEffect(() => {
    if (!dialogLineRef || !dialogLineRef.current) {
      return;
    }

    dialogLineRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [dialogLineRef, currentTime]);

  return (
    <Box className='transcription-container'>
      {transcription.lines.map((line, index) => {
        const [time, label, content] = line;
        const speakerData = resolveSpeaker(label);
        const timeRange = transcription.timeRanges[index];

        const closeInTime = isCloseInTime({
          currentTime,
          fromTime: timeRange[0],
          untilTime: timeRange[1],
        });

        return (
          <Button
            key={index}
            className={`dialog-line ${isPlaying ? 'playing' : ''} ${
              closeInTime ? 'highlight' : ''
            }`}
            onClick={() => onClickTextLine(timeRange[0])}
            ref={closeInTime ? dialogLineRef : null}>
            <Box className='dialog-header'>
              <Typography className='time'>{time}</Typography>
              <Typography className={`dialog-speaker ${speakerData.kind}`}>
                {speakerData.name}
              </Typography>
            </Box>

            <Box className='dialog-text'>{content}</Box>
          </Button>
        );
      })}
    </Box>
  );
};

export default Transcription;

import { getAge, getFormattedDateFromISOString } from 'helpers/date';
import {
  AppointmentSummaryOutput,
  DiagnosisSummaryOutput,
  Laboratory,
  MedicalProblemOutput,
  MedicalRemainingProblemsOutput,
  Medication,
  MeetingContextLocation,
  MeetingContextOutput,
  MeetingSummaryStatus,
  NoteDetail,
  Procedure,
  ScribeOutputType,
  TextOutput,
  VitalSignResponse,
} from 'node-api/scribe/scribe.types';
import { camelCaseToSentence } from 'utils/stringHelpers';

export const ENABLED_TO_SHOW_IN_NOTES = false;

const convertDataReviewSectionToString = (
  data: (VitalSignResponse | Laboratory | Medication | Procedure)[]
) =>
  data.map((d) => {
    return Object.entries(d)
      .map(([key, value]) => `      ${camelCaseToSentence(key)}: ${value}`)
      .join('\n');
  });

// TODO: Add this code in convertMedicalProblemsToString when we have the medical configuration ready to associate them with the medical problems section
/*
    ${`Exams:\n\n${exams
      .map((data) => {
        const labAsString = `      ${data.procedure?.toUpperCase()}: ${data.status} (${
          data.date ? getFormattedDateFromISOString(data.date, 'YYYY-MM-DD') : 'N/A'
        })`;

        return labAsString;
      })
      .join('\n\n')}`}
*/

const convertMedicalProblemsToString = (problems: MedicalProblemOutput[]) => {
  const problemsAsStrings = problems
    .map((medicalProblem: MedicalProblemOutput) => {
      const { labs, exams, ...rest } = medicalProblem.dataReview;

      return `
${medicalProblem?.name} ${medicalProblem?.icd10Code}

  History:
  ${medicalProblem?.history}

  Data Review:
    ${`Labs:\n\n${labs
      .map((data) => {
        const labAsString = `      ${data.observation?.toUpperCase()}: ${data.value} (${
          data.resultDate ? getFormattedDateFromISOString(data.resultDate, 'YYYY-MM-DD') : 'N/A'
        })`;

        return labAsString;
      })
      .join('\n\n')}`}

    ${
      ENABLED_TO_SHOW_IN_NOTES
        ? Object.entries(rest)
            .map(([section, data]) => {
              const dataAsString = convertDataReviewSectionToString(data);

              return `${camelCaseToSentence(section)}:\n\n${dataAsString.join('\n\n')}`;
            })
            .join('\n\n')
        : ''
    }

  Subjective Updates:\n${medicalProblem?.subjectiveUpdates}

  Assessment and Discussion:\n${medicalProblem?.assessmentAndDiscussion}

  Plan:\n${medicalProblem?.plan || 'N/A'}
`;
    })
    .join('\n');

  return `
Problems:
${problemsAsStrings}
  `;
};

const convertMedicalContextToString = (context: MeetingContextOutput) => {
  const telehealthConsent =
    context.appointmentLocation === MeetingContextLocation.Telehealth
      ? ` The patient gave informed consent for telehealth during registration, including rights, services, and privacy measures, willingly participating in the virtual consultation.`
      : '';

  return `${context.patientName || 'unknown'} is a ${
    getAge(context.patientDOB) || 'unknown-age'
  } year old ${
    context.patientGender || 'unknown-gender'
  } who is here for an Initial Evaluation and Assessment for ${
    context.mainProblem || 'unknown-main-problem'
  }. This visit was conducted ${
    context.appointmentLocation || 'unknown-location'
  }.${telehealthConsent}`;
};

const convertMedicalDurationToString = (duration: string) => {
  return `\nDuring this encounter, I spent approximately ${duration} minutes`;
};

const convertDiagnosisSummaryToString = (diagnosisSummary: DiagnosisSummaryOutput) => {
  const diagnosesFormatted = Object.entries(diagnosisSummary)
    .map(([premise, diagnoses]) => {
      const medicalProblem = camelCaseToSentence(premise);

      return `${medicalProblem}:\n${diagnoses.join(', ')}`;
    })
    .join('\n');

  return `During this encounter, I addressed the following:\n\n${diagnosesFormatted}`;
};

const convertAppointmentSummaryToString = (appointmentSummary: AppointmentSummaryOutput) => {
  return `
Originating site: ${appointmentSummary.originatingSite}
Distant site: ${appointmentSummary.distantSite}
Time started: ${getFormattedDateFromISOString(appointmentSummary.startingTime, 'hh:mm')}
Time ended: ${getFormattedDateFromISOString(appointmentSummary.endingTime, 'hh:mm')}
      `;
};

const convertMedicalRemainingProblemsToString = (
  medicalRemainingProblems: MedicalRemainingProblemsOutput
) => {
  return `
The remaining problems were not addressed today. During this encounter, I reviewed the data
point mentioned in the clinical note, which included face to face and time spent:
${medicalRemainingProblems.medicalProcedures?.join(', ') || 'unknown-medical-procedures'}

I also additionally addressed the following problems:
${medicalRemainingProblems.additionalProblem || 'unknown'}

Based on CMS definitions, I estimate that risk of treatment and diagnostic plan is
${medicalRemainingProblems.mdmCode || 'unknown'}
`;
};

const convertStaticTextToString = (data: TextOutput) => {
  return `${data.text}`;
};

export const prepareSectionsForCopying = (note: NoteDetail): string => {
  if (!note.content) {
    return '';
  }

  return note.content
    .sort((a, b) => a.order - b.order)
    .map((section) => {
      const { outputType, data } = section;

      switch (outputType) {
        case ScribeOutputType.MeetingContext:
          return convertMedicalContextToString(data);

        case ScribeOutputType.MeetingDuration:
          return convertMedicalDurationToString(data.meetingDuration);

        case ScribeOutputType.MedicalProblems:
          return convertMedicalProblemsToString(data);

        case ScribeOutputType.DiagnosisSummary:
          return convertDiagnosisSummaryToString(data);

        case ScribeOutputType.AppointmentSummary:
          return convertAppointmentSummaryToString(data);

        case ScribeOutputType.MedicalRemainingProblems:
          return convertMedicalRemainingProblemsToString(data);

        case ScribeOutputType.StaticText:
          return convertStaticTextToString(data);

        default:
          return '';
      }
    })
    .join('\n');
};

export const convertSummaryStatusToReadableString = (
  meetingStatus: MeetingSummaryStatus | 'unknown'
): string => {
  switch (meetingStatus) {
    case MeetingSummaryStatus.ReadyForReview:
      return 'Ready for Review';
    case MeetingSummaryStatus.Signed:
      return 'Signed';
    case MeetingSummaryStatus.WaitingForSignature:
      return 'Waiting for Signature';
    default:
      return 'N/A';
  }
};

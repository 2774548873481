import {
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import axios from 'axios';
import { IconButton } from 'components/elements';
import Spinner from 'components/elements/loaders/spinner';
import { SectionMessage, SectionTitle } from 'components/general-styles';
import Icon from 'components/icon';
import { FILTER_BY_NO_CLINIC_ID } from 'globalConstants';
import useDebounce from 'hooks/useDebounce';
import { EFeatures } from 'lib/feature/features.types';
import { orderBy } from 'lodash';
import { AttentionDimension, AttentionResponseEntry } from 'node-api/attention/AttentionApi.types';
import { AttentionClient } from 'node-api/attention/AttentionApiClient';
import { MyPanelLegacyClient } from 'node-api/panel/MyPanelLegacyClient';
import { ILegacyMyPanelResponseEntry } from 'node-api/panel/MyPanelLegacyClient.types';
import { FC, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';
import { ClickableElement } from 'services/mixpanel/shared-mixpanel-service';
import { ClinicContext } from 'state/contexts/clinic';
import { FeaturesContext } from 'state/contexts/features';
import { debug } from 'utils/helpers';
import { getMemberProfileUrl } from 'utils/members';
import { ATTENTION_DIMENSION_COLOR } from './constants';
import { Image, InlineRecord, InlineRecordItem, Item, Record, Text } from './style';

const DIMENSION_EMOJI: { [key in AttentionDimension]?: string } = {
  [AttentionDimension.Food]: '🍎',
  [AttentionDimension.FoodComments]: '🍎💬',
  [AttentionDimension.CardComments]: '🎫💬',
  [AttentionDimension.MemberChat]: '💬',
  [AttentionDimension.TeamChat]: '👫💬',
  [AttentionDimension.Vitals]: '🩸🧪',
  [AttentionDimension.Weights]: '👣',
};

const DIMENSION_EMOJI_DEFAULT = '👀';

const COLUMNS: { label: string; field: string }[] = [
  {
    label: 'Patient',
    field: 'full_name',
  },
  {
    label: 'Status',
    field: 'patient_status',
  },
  {
    label: 'Days in the Program',
    field: 'program_start_days_ago',
  },
  {
    label: 'Current Program',
    field: 'current_program',
  },
  {
    label: 'Percent Weight Loss',
    field: 'percent_weightloss',
  },
];

const MyPanelList: FC<RouteComponentProps> = ({ history }) => {
  const [entirePanel, setEntirePanel] = useState<ILegacyMyPanelResponseEntry[]>([]);
  const [visiblePatients, setVisiblePatients] = useState<ILegacyMyPanelResponseEntry[]>([]);
  const [attentionNeed, setAttentionNeed] = useState<{
    [memberId: number]: AttentionResponseEntry[];
  }>({});

  const [clinicFilter, setClinicFilter] = useState<number | null>(null);
  const [nameOrProgramFilter, setNameOrProgramFilter] = useState<string>('');
  const [sortBy, setSortBy] = useState({ field: '', order: '' });

  const [loading, setLoading] = useState(true);

  const { featuresState } = useContext(FeaturesContext);
  const { clinicState } = useContext(ClinicContext);

  const canMarkAsReviewed = featuresState?.[EFeatures.ProviderProfileShowNeedsAttention];
  const debouncedNameOrProgramFilter = useDebounce(nameOrProgramFilter);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const getPatients = async () => {
      const myPanelClient = new MyPanelLegacyClient();
      const attentionClient = new AttentionClient();
      try {
        const myPanel = await myPanelClient.getMyPanel(source.token);

        setEntirePanel(myPanel);
        // Only if the panel is successfully retrieved, get the attention need.
        // This will only be displayed if the feature is enabled, but we can fetch it regardless
        try {
          const attention = await attentionClient.getMembersAttentionNeed({
            memberIds: myPanel.map((p) => p.id),
          });

          setAttentionNeed(attention);
        } catch (err) {
          debug(err as string);
        }

        setLoading(false);
      } catch (err) {
        if (!axios.isCancel(err)) {
          debug(err as string);
          setLoading(false);
        }
      }
    };

    getPatients();

    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    const filtered: ILegacyMyPanelResponseEntry[] = [];

    if (clinicFilter) {
      filtered.push(...entirePanel.filter((p) => p.clinic_id === clinicFilter));
    }

    if (debouncedNameOrProgramFilter) {
      const source = !!clinicFilter ? filtered : entirePanel;

      const matchingByNameOrProgram = source.filter(
        (p) =>
          p.full_name.toLowerCase().includes(debouncedNameOrProgramFilter.toLowerCase()) ||
          p.current_program.toLowerCase().includes(debouncedNameOrProgramFilter.toLowerCase())
      );

      filtered.length = 0;
      filtered.push(...matchingByNameOrProgram);
    }

    const filtersRequested = !!clinicFilter || !!debouncedNameOrProgramFilter;

    setVisiblePatients(
      orderBy(filtersRequested ? filtered : entirePanel, [sortBy.field], [sortBy.order])
    );
  }, [debouncedNameOrProgramFilter, sortBy, entirePanel, clinicFilter]);

  const renderAttentionNeeded = (attention: AttentionResponseEntry[]) =>
    attention &&
    attention.map((a) => (
      <span
        key={a.dimensionKey}
        style={{
          display: canMarkAsReviewed ? undefined : 'none',
          color: 'white',
          padding: 4,
          fontSize: 13,
          marginLeft: 2,
          borderRadius: 3,
          backgroundColor:
            ATTENTION_DIMENSION_COLOR[a.dimensionKey] || ATTENTION_DIMENSION_COLOR.default,
        }}>
        {DIMENSION_EMOJI[a.dimensionKey] || DIMENSION_EMOJI_DEFAULT}
      </span>
    ));

  const renderClinicOptions = (): JSX.Element[] => {
    const { clinicsAssignedToProvider } = clinicState;

    return [
      ...clinicsAssignedToProvider.map((clinic, index) => (
        <MenuItem value={clinic.id} key={`${clinic.id}-${index}`}>
          {clinic.name}
        </MenuItem>
      )),
      <MenuItem value={FILTER_BY_NO_CLINIC_ID}>All</MenuItem>,
    ];
  };

  const trackViewProfile = (member: ILegacyMyPanelResponseEntry, source: string) => {
    MixpanelWrapperInstance.track(EventNames.MemberProfileOpen, {
      targetLabel: 'View Profile',
      targetType: ClickableElement.LINK,
      'member-id': member.uuid,
      source,
    });
  };

  const handleViewProfile = (member: ILegacyMyPanelResponseEntry, source: string) => {
    trackViewProfile(member, source);
    history.push(getMemberProfileUrl(member));
  };

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
          alignContent: 'center',
        }}>
        <SectionTitle>My Panel: Members</SectionTitle>
        {loading ? (
          <SectionMessage>
            <p>Loading members...</p>
            <Spinner size={20} />
          </SectionMessage>
        ) : (
          <Stack
            sx={{
              width: '40%',
              marginTop: 2,
              padding: 0,
              justifyContent: 'space-between',
            }}
            direction='row'
            gap={1}>
            <SectionMessage style={{ flex: 1 }}>
              <TextField
                autoFocus
                type='text'
                label='Filter by Name or Program'
                variant='outlined'
                value={nameOrProgramFilter}
                onChange={(event) => {
                  const { value } = event.target;

                  setNameOrProgramFilter(value);
                  MixpanelWrapperInstance.track(EventNames.MyPanelFilterPatient, { Value: value });
                }}
                /* When pressing enter, open first patient profile */
                onKeyDown={(ev) => {
                  if (ev.key === 'Enter' && visiblePatients.length > 0 && nameOrProgramFilter) {
                    ev.preventDefault();
                    handleViewProfile(visiblePatients[0], 'panel-search-field');
                  }
                }}
              />
            </SectionMessage>

            {clinicState.clinicsAssignedToProvider.length > 1 && (
              <FormControl sx={{ flex: 1 }}>
                <InputLabel>Clinics</InputLabel>
                <Select
                  label='Clinics'
                  defaultValue=''
                  onChange={(e: SelectChangeEvent) => {
                    const clinicId = parseInt(e.target.value);

                    setClinicFilter(clinicId === FILTER_BY_NO_CLINIC_ID ? null : clinicId);
                  }}>
                  {renderClinicOptions()}
                </Select>
              </FormControl>
            )}
          </Stack>
        )}
      </div>
      {!loading &&
        (visiblePatients.length > 0 ? (
          <>
            <InlineRecord>
              {COLUMNS.map((column) => (
                <InlineRecordItem key={`column-${column.field}`}>
                  <Text>{column.label}</Text>
                  <IconButton
                    onClick={() => {
                      MixpanelWrapperInstance.track(EventNames.MyPanelSortPatient, {
                        Column: column.field,
                      });

                      setSortBy((p) => ({
                        field: column.field,
                        order: p.order === 'asc' ? 'desc' : 'asc',
                      }));
                    }}>
                    <Icon icon='sort' color='grayDark' size={24} />
                  </IconButton>
                </InlineRecordItem>
              ))}
            </InlineRecord>

            {visiblePatients.map((p, index) => (
              <Record
                key={`patient-${p.id}`}
                style={index === 0 ? { backgroundColor: '#0CC1' } : {}}>
                <Item style={{ cursor: 'pointer' }}>
                  <Image size={26}>
                    <Link
                      href={getMemberProfileUrl(p)}
                      onClick={() => trackViewProfile(p, 'panel-member-avatar')}>
                      {p.avatar ? (
                        <img src={p.avatar?.thumb?.url} alt={`${p.first_name} ${p.last_name}`} />
                      ) : (
                        <Icon icon='user' size={26} />
                      )}
                    </Link>
                  </Image>
                </Item>
                <Item style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                  <Link
                    href={getMemberProfileUrl(p)}
                    onClick={() => trackViewProfile(p, 'panel-member-name')}>
                    {p.first_name} {p.last_name}
                    {canMarkAsReviewed && renderAttentionNeeded(attentionNeed[p.id])}
                  </Link>
                </Item>
                <Item>{p.patient_status}</Item>
                <Item>
                  {p.current_program
                    ? `${p.program_start_date} (${p.program_start_days_ago} days)`
                    : ''}
                </Item>
                <Item>{p.current_program}</Item>
                <Item>{p.percent_weightloss ? `${p.percent_weightloss}%` : ''}</Item>

                <Link
                  href={getMemberProfileUrl(p)}
                  onClick={() => trackViewProfile(p, 'panel-member-link')}
                  style={{ whiteSpace: 'nowrap', textDecoration: 'underline' }}>
                  View Profile
                </Link>
              </Record>
            ))}
          </>
        ) : nameOrProgramFilter !== '' ? (
          <SectionMessage>
            <p>No patients found</p>
          </SectionMessage>
        ) : (
          <SectionMessage>
            <p>There are no patients</p>
          </SectionMessage>
        ))}
    </div>
  );
};

export default MyPanelList;

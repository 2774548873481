// Local Storage
export const ORIGINAL_USER_LOCAL_STORAGE_KEY = 'Origu';
export const CALENDAR_TIMEZONE_LOCAL_STORAGE_KEY = 'calendar-timezone';
export const VIEW_MODE_LOCAL_STORAGE_KEY = 'appointment-summary-mode';
export const SELECTED_NOTE_FOR_PREVIEW_SUMMARY_ID_KEY = 'selected-note-for-preview-summary-id';

export const STATUS_HIDDEN = 'hidden';
export const DIRECT_ROOM = 'direct';
export const TEAM_ROOM = 'team';
export const ROLE_UNAVAILABLE = 'N/A';
export const PROVIDER_DASHBOARD = 'provider-dashboard';

export const IMAGE_IDENTIFIER = '*image*';
export const SEND_GRID_MISSED_APPOINTMENTS_PARAM = { name: 'fsgma', value: 't' };

export enum Conversion {
  Default = 1,
  GramsToLibs = 2,
}

export const GRAMS_PER_POUND = 453.4;

export const FILTER_BY_NO_CLINIC_ID = -1;
export const FILTER_BY_NO_MEETING_STATUS = -1;

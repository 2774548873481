import {
  QueryBuilder as ClockIcon,
  FmdGood as LocationIcon,
  Description as NoteIcon,
  LocalPhone as PhoneIcon,
  Public as TimeZoneIcon,
} from '@mui/icons-material';
import { Avatar, Box, Button, Link, Typography } from '@mui/material';
import LoadingButton from 'components/atoms/wrappers/LoadingButton';
import { convertSummaryStatusToReadableString } from 'components/elements/notes/note/NotePreview/format';
import { SELECTED_NOTE_FOR_PREVIEW_SUMMARY_ID_KEY } from 'globalConstants';
import { DateFormats } from 'helpers/date';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { ScheduledAppointment } from 'node-api/schedule/AppointmentsClient';
import { schedulingV3Client } from 'node-api/scheduleV3/client.v3';
import { EntityWithSummaryInfo } from 'node-api/scribe/scribe.types';
import { FC, useContext, useState } from 'react';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';
import { ClickableElement } from 'services/mixpanel/shared-mixpanel-service';
import { UserContext } from 'state/contexts/user';
import { debug, saveOnLocalStorage } from 'utils/helpers';
import { getMemberAvatarUrl, getMemberProfileUrl } from 'utils/members';
import {
  formatAppointmentStartEnd,
  getAppointmentSimplifiedName,
  getAppointmentTypeCodes,
} from 'utils/schedule';
import './SchedulePreviewCard.css';

type Props = {
  appointment: EntityWithSummaryInfo<ScheduledAppointment>;
  displayNotes: boolean;
  timezone: string;
};

export const SchedulePreviewCard: FC<Props> = ({ appointment, displayNotes, timezone }) => {
  const [isJoinCallButtonLoading, setIsJoinCallButtonLoading] = useState(false);

  const { userState } = useContext(UserContext);

  const handleViewNote = () => {
    saveOnLocalStorage(
      SELECTED_NOTE_FOR_PREVIEW_SUMMARY_ID_KEY,
      { meetingId: appointment.meetingId, startAt: appointment.startAt },
      true
    );

    window.open(`/members/${appointment.user.uuid}/notes`, '_blank');
  };

  const handleJoinCall = async () => {
    MixpanelWrapperInstance.track(EventNames.MemberCallJoin, {
      targetLabel: 'Join Call',
      targetType: ClickableElement.BUTTON,
      targetLocation: 'Home',
      memberUuid: appointment.user.uuid,
      memberName: `${appointment.user.firstName} ${appointment.user.lastName}`,
      providerUuid: userState.uuid,
      source: 'weekly-view-preview-card',
    });

    setIsJoinCallButtonLoading(true);

    try {
      const url = await schedulingV3Client.getProviderVideoUrl(
        appointment.user,
        {
          id: userState.id ?? -1,
          uuid: userState.uuid ?? '',
        },
        'provider',
        'weekly-view-preview-card'
      );

      window.open(url, '_blank');
    } catch (err) {
      debug(`${err}`);
    }

    setIsJoinCallButtonLoading(false);
  };

  const trackGoToMemberProfile = () => {
    MixpanelWrapperInstance.track(EventNames.MemberProfileOpen, {
      targetLabel: 'View Profile',
      targetType: ClickableElement.LINK,
      'member-id': appointment.user.uuid,
      memberName: `${appointment.user.firstName} ${appointment.user.lastName}`,
      source: 'weekly-view-preview-card',
    });
  };

  const [, , appointmentLocation] = getAppointmentTypeCodes(appointment.appointmentType);

  // Can only join call if appointment will start between 15 minutes before and 15 minutes after the current time
  const isAppointmentSoon = moment().isBetween(
    moment(appointment.startAt).subtract(15, 'minutes'),
    moment(appointment.startAt).add(15, 'minutes')
  );
  const appointmentTimeLabel: 'Past' | 'Current' | 'Next' | 'Future' = moment().isAfter(
    appointment.startAt
  )
    ? isAppointmentSoon
      ? 'Current'
      : 'Past'
    : moment().isSame(appointment.startAt, 'day')
    ? 'Next'
    : 'Future';

  const canJoin = appointmentLocation === 'OL' && isAppointmentSoon;

  const memberFullName = [appointment.user.firstName, appointment.user.lastName].join(' ');
  const memberTimezone = moment()
    .tz(appointment.user.timezone)
    .format(DateFormats.TIMEZONE_ABBREVIATED_FORMAT);

  const dayAsText = momentTimezone(appointment.startAt).tz(timezone).format('MMMM, dddd Do');
  const timesAsText = formatAppointmentStartEnd(appointment, timezone);
  const avatarUrl = getMemberAvatarUrl(appointment.user);

  return (
    <Box className='schedulePreviewCard-container'>
      <Typography className='appointment-title'>{appointmentTimeLabel} Appointment</Typography>

      <Box className='member-info'>
        <Avatar alt={memberFullName} src={avatarUrl.thumb.url} sx={{ width: 70, height: 70 }} />
        <Box className='info'>
          <Typography variant='body1' className='member-name'>
            {memberFullName}
          </Typography>
          <Box className='info-with-icon'>
            <PhoneIcon fontSize='small' />
            <Typography variant='body2' className='member-phone'>
              {appointment.user.phone}
            </Typography>
          </Box>
          <Box className='info-with-icon'>
            <TimeZoneIcon />
            <Typography variant='body2' className='member-timezone'>
              {memberTimezone}
            </Typography>
          </Box>
        </Box>
        <Box className='link'>
          <Link
            href={getMemberProfileUrl(appointment.user)}
            onClick={trackGoToMemberProfile}
            target='_blank'
            style={{ whiteSpace: 'nowrap', textDecoration: 'underline' }}>
            View Profile
          </Link>
        </Box>
      </Box>
      <hr />
      <Box className='appointment-info'>
        <Box className='info'>
          <Typography variant='body1' className='appointment-type'>
            {getAppointmentSimplifiedName(appointment.appointmentType)}
          </Typography>
          <Box className='info-with-icon'>
            <ClockIcon fontSize='small' color='disabled' />
            <Typography variant='body2' className='appointment-date'>
              {dayAsText} • {timesAsText}
            </Typography>
          </Box>
          <Box className='info-with-icon'>
            <LocationIcon fontSize='small' color='disabled' />
            <Typography variant='body2' className='appointment-location'>
              {appointmentLocation === 'OL' ? 'Online' : 'In Person'}
            </Typography>
          </Box>
        </Box>
        <Box className='link'>
          <LoadingButton
            onClick={handleJoinCall}
            loading={isJoinCallButtonLoading}
            disabled={!canJoin}
            variant='contained'>
            {appointmentLocation === 'OL' ? 'Join Call' : 'In person'}
          </LoadingButton>
        </Box>
      </Box>
      <hr />
      {displayNotes && (
        <Box className='notes-info'>
          <Box className='info'>
            <Typography className='title'>Notes</Typography>
            <Box className='info-with-icon'>
              <NoteIcon fontSize='small' color='disabled' />
              <Typography variant='body2'>
                {convertSummaryStatusToReadableString(appointment.meetingStatus || 'unknown')}
              </Typography>
            </Box>
          </Box>
          <Box className='link'>
            <Button
              variant='outlined'
              disabled={!appointment.meetingStatus}
              onClick={handleViewNote}>
              View Note
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

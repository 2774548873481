import Tab from 'components/elements/tab';
import { Box, BoxContent, Tabs } from 'components/general-styles';
import Icon from 'components/icon';
import { TabBox } from 'components/main/cards/style';
import { getDateFromNMonthsAgo, getDateFromNMonthsLater } from 'helpers/date';
import { useState } from 'react';
import { TrackableElement } from 'services/mixpanel/mixpanel-service';
import { ChangeElementTypes, EventHandlerNames } from 'services/mixpanel/mixpanel.types';
import { Appointments } from './list';

const APPOINTMENT_TAB_EVENT_NAME = 'Clicked Appointment Tabs';

const TABS_LIST = [
  {
    name: 'appointments-active',
    label: 'Active',
    componentType: 'active',
  },
  {
    name: 'appointments-completed',
    label: 'Completed',
    componentType: 'completed',
  },
];

const NOTES = [
  {
    key: 'note-1',
    icon: 'pushPin',
    text: `Appointments: Only the appointments in the range of ${getDateFromNMonthsAgo(
      4
    )} - ${getDateFromNMonthsLater(4)} are displayed.`,
  },
  {
    key: 'note-2',
    icon: 'pushPin',
    text: 'Place Of Service: This information cannot be edited.',
  },
];

const AppointmentListForTabs = ({ patientId }: { patientId: number }) => {
  const [selected, setSelected] = useState('appointments-active');

  return (
    <>
      <Box>
        <BoxContent>
          {NOTES.map((note) => (
            <div key={note.key}>
              <Icon icon={note.icon} size={18} color='teal' /> {note.text}
            </div>
          ))}
        </BoxContent>

        <Box>
          <Tabs>
            {TABS_LIST.map((tab) => (
              <TrackableElement
                customEventName={APPOINTMENT_TAB_EVENT_NAME}
                name={tab.name}
                type={ChangeElementTypes.CHECKBOX}
                eventHandlerName={EventHandlerNames.ONCLICK}
                key={`tab-${tab.name}`}>
                <Tab
                  onClick={() => {
                    setSelected(tab.name);
                  }}
                  selected={selected === tab.name}
                  tab={tab}
                  key={`tab-${patientId}-${tab.name}`}
                />
              </TrackableElement>
            ))}
          </Tabs>
          <TabBox>
            {TABS_LIST.map(
              (tab) =>
                selected === tab.name && (
                  <Appointments
                    key={`tab-${tab.componentType}`}
                    type={tab.componentType}
                    memberId={patientId}
                  />
                )
            )}
          </TabBox>
        </Box>
      </Box>
    </>
  );
};

export default AppointmentListForTabs;

import Tab from 'components/elements/tab';
import { Box, Section, Tabs } from 'components/general-styles';
import MainProfileActions from 'components/main';
import { CARDS, CARD_PENDING_COMMENTS_REVIEW_LIST_NAMES } from 'config';
import { CardTabs } from 'interfaces/cards';
import { EFeatures } from 'lib/feature/features.types';
import { FC, useContext, useState } from 'react';
import { ILegacyNodeCardListEntry } from 'services/card-service.types';
import { TrackableElement } from 'services/mixpanel/mixpanel-service';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { ClickElementTypes, EventHandlerNames } from 'services/mixpanel/mixpanel.types';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';
import { ClickableElement } from 'services/mixpanel/shared-mixpanel-service';
import { CommentContext } from 'state/contexts/comment';
import { FeaturesContext } from 'state/contexts/features';
import { UserContext } from 'state/contexts/user';
import { ActionTypes } from 'state/reducers/comment';
import CardDetailsModal from '../CardsDetail/CardDetailsModal';
import PostCardSequence from '../PostCardSequence/PostCardSequence';
import CardHistory from '../card-history';
import RecentCards from '../recent-card/RecentCards';
import { TabBox } from '../style';
import CardsPendingCommentsCardEntry from './CardsPendingCommentsCardEntry';

const CardsPendingCommentsTab: FC<{ memberId: number; reviewList?: string }> = ({
  memberId,
  reviewList,
}) => {
  const { commentState, commentDispatch } = useContext(CommentContext);
  const { featuresState } = useContext(FeaturesContext);
  const { userState } = useContext(UserContext);

  const [selected, setSelected] = useState(
    reviewList && CARD_PENDING_COMMENTS_REVIEW_LIST_NAMES.includes(reviewList)
      ? 'cards-pending-comments'
      : 'card-history'
  );

  const CARDS_POP = CARDS.tabs.filter((tab) => {
    if (!featuresState?.[EFeatures.ProviderShowRecentCards] && tab.name === CardTabs.RecentCard) {
      return false;
    }

    if (!featuresState?.[EFeatures.ProviderShowPcs] && tab.name === CardTabs.PostSequence) {
      return false;
    }

    return true;
  });

  const CARD_TAB_EVENT_NAME = 'Clicked Card Tabs';

  return (
    <>
      {/* activate all buttons from profile page */}
      <MainProfileActions key={`main-profile-buttons-${memberId}`} />
      <Section>
        <Box>
          <Tabs>
            {CARDS_POP.map((tab) => (
              <TrackableElement
                customEventName={CARD_TAB_EVENT_NAME}
                name={tab.name}
                type={ClickElementTypes.TAB}
                eventHandlerName={EventHandlerNames.ONCLICK}
                key={`tab-${tab.name}`}>
                <Tab
                  onClick={() => {
                    MixpanelWrapperInstance.track(EventNames.CardsClickTab, {
                      targetLabel: tab.name,
                      targetType: ClickableElement.TAB,
                      memberId,
                      providerId: userState.id,
                    });
                    setSelected(tab.name);
                  }}
                  selected={selected === tab.name}
                  tab={tab}
                  key={`tab-${memberId}-${tab.name}`}
                />
              </TrackableElement>
            ))}
          </Tabs>
          <TabBox>
            {selected === CardTabs.History && (
              <CardHistory patientId={memberId} key={`${CardTabs.History}-${memberId}`} />
            )}
            {selected === CardTabs.PendingComments && (
              <CardsPendingCommentsCardEntry
                memberId={memberId}
                key={`${CardTabs.PendingComments}-${memberId}`}
              />
            )}
            {selected === CardTabs.PostSequence && (
              <PostCardSequence key={`${CardTabs.PostSequence}-${memberId}`} />
            )}

            {selected === CardTabs.RecentCard && <RecentCards patientId={memberId} />}

            {commentState.modal && commentState.element && (
              <CardDetailsModal
                card={
                  commentState.element as ILegacyNodeCardListEntry /** TODO: This context is used with inconsistent types */
                }
                memberId={memberId}
                onClose={(element_id) =>
                  commentDispatch({
                    type: ActionTypes.TOGGLE_MODAL,
                    payload: { element_id },
                  })
                }
              />
            )}
          </TabBox>
        </Box>
      </Section>
    </>
  );
};

export default CardsPendingCommentsTab;

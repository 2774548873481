import { humanReadableDate } from 'helpers/date';
import { FC, useContext } from 'react';
import { CommentContext } from 'state/contexts/comment';
import { ActionTypes } from 'state/reducers/comment';
import { ICard } from '../../../../interfaces/cards';
import { BoxCard, CardRecord } from './style';

const CardItem: FC<{ card: ICard; extraDays: number }> = ({ card, extraDays }) => {
  const { commentDispatch } = useContext(CommentContext);

  return (
    <BoxCard>
      <CardRecord
        customCursor='pointer'
        onClick={() =>
          commentDispatch({
            type: ActionTypes.TOGGLE_MODAL,
            payload: { element: card },
          })
        }>
        {card.title}
      </CardRecord>
      <CardRecord customWidth='5%'>{card.days_delay}</CardRecord>
      <CardRecord>{humanReadableDate(card.days_delay + extraDays - 1)}</CardRecord>
    </BoxCard>
  );
};

export default CardItem;

import { Stack, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import LoadingButton from 'components/atoms/wrappers/LoadingButton';
import Spinner from 'components/elements/loaders/spinner';
import momentTimezone from 'moment-timezone';
import { schedulingV3Client } from 'node-api/scheduleV3/client.v3';
import { AppointmentSummary, AppointmentSummaryScope } from 'node-api/scheduleV3/types.v3';
import { fillEntityOutWithSummaryInfo } from 'node-api/scribe/helpers';
import { EntityWithSummaryInfo } from 'node-api/scribe/scribe.types';
import { FC, useEffect, useState } from 'react';
import AuthService from 'services/auth-service';
import { debug } from 'utils/helpers';
import ScheduleAppointmentCard from './ScheduleAppointmentCard';
import { Action } from './style';

const ActiveSessions: FC<{ onDisplayMember: (patient: { uuid: string }) => void }> = ({
  onDisplayMember,
}) => {
  const [displayNotes, setDisplayNotes] = useState<boolean>(false);
  const [appointments, setAppointments] = useState<EntityWithSummaryInfo<AppointmentSummary>[]>([]);
  const [appointmentsScope, setAppointmentsScope] = useState<AppointmentSummaryScope>('upcoming');
  const [now, setNow] = useState(momentTimezone());
  const [fetching, setFetching] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<{ current: number; total: number }>({
    current: 1,
    total: 0,
  });

  const fetchAppointmentsWithinScope = async ({
    pageNumber,
    scope,
  }: {
    pageNumber: number;
    scope: AppointmentSummaryScope;
  }): Promise<EntityWithSummaryInfo<AppointmentSummary>[]> => {
    try {
      const response = await schedulingV3Client.getProviderAppointmentsSummary(
        pageNumber,
        scope,
        'OL'
      );

      if (response) {
        setPagination({ total: response.totalPages, current: pageNumber });
      }

      const resultingAppointments = response?.appointments || [];

      if (resultingAppointments.length > 0) {
        return await fillEntityOutWithSummaryInfo(resultingAppointments);
      }

      return resultingAppointments;
    } catch (err) {
      debug(err as string);
    }

    return [];
  };

  useEffect(() => {
    fetchAppointmentsWithinScope({ pageNumber: 1, scope: appointmentsScope })
      .then((data) => setAppointments(data))
      .finally(() => setFetching(false));

    const updateNowHandler = setInterval(() => setNow(momentTimezone()), 10000);

    return () => {
      clearInterval(updateNowHandler);
    };
  }, []);

  useEffect(() => {
    const resolveViewNotesStatus = async () => {
      const config = await AuthService.getConfig();

      setDisplayNotes(!!config?.provider.scribeConfig.viewNotes);
    };
    resolveViewNotesStatus();
  }, []);

  const handleLoad = async () => {
    setLoading(true);

    fetchAppointmentsWithinScope({
      pageNumber: pagination.current + 1,
      scope: appointmentsScope,
    })
      .then((data) => setAppointments((a) => [...a, ...data]))
      .finally(() => setLoading(false));
  };

  const handleFetchAppointmentsWithinScope = () => {
    const scope: AppointmentSummaryScope = appointmentsScope === 'upcoming' ? 'past' : 'upcoming';

    setAppointmentsScope(scope);
    setFetching(true);

    fetchAppointmentsWithinScope({ pageNumber: 1, scope })
      .then((data) => setAppointments(data))
      .finally(() => setFetching(false));
  };

  return (
    <>
      <Stack className='active-sessions__root'>
        <Tabs
          value={appointmentsScope}
          onChange={handleFetchAppointmentsWithinScope}
          className='active-sessions__tabs-right'>
          <Tab label='Upcoming' value='upcoming' />
          <Tab label='Past' value='past' />
        </Tabs>

        {fetching ? (
          <Stack
            sx={{
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'center',
              marginBottom: 5,
              gap: 5,
            }}>
            <Typography>Loading appointments</Typography>
            <Spinner size={20} />
          </Stack>
        ) : appointments.length > 0 ? (
          <>
            {appointments.map((a) => (
              <ScheduleAppointmentCard
                key={`schedule-appointment-${a.id}`}
                appointment={a}
                currentTime={now}
                displayNotes={displayNotes}
                isPastAppointment={appointmentsScope === 'past'}
                onDisplayMember={onDisplayMember}
              />
            ))}
            <Action>
              {pagination.current + 1 <= pagination.total && (
                <LoadingButton variant='contained' loading={loading} onClick={handleLoad}>
                  Load more
                </LoadingButton>
              )}
            </Action>
          </>
        ) : (
          <Stack sx={{ alignItems: 'center', marginBottom: 2 }}>
            <Typography>There are no {appointmentsScope} appointments.</Typography>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default ActiveSessions;

import MetricCardElement from 'components/elements/metric-card/MetricCardElement';
import Toast from 'components/toast';
import { getDateFromNDaysAgo } from 'helpers/date';
import { DashboardFilters, DashboardMetricValuesQueryParams } from 'node-api/dashboard.types';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import DashboardService from 'services/dashboard';
import { UserContext } from 'state/contexts/user';
import MetricsFilter from './MetricsFilter';
import { DashboardContainer, MetricsContainer, NoMetrics } from './style';
import {
  DashboardMetrics,
  MetricLoadState,
  MetricRawValue,
  MetricStaticData,
  MetricValue,
} from './types';

enum ErrorMessages {
  NO_FILTERS_MESSAGE = 'Please complete the filters to show data',
  NO_PANEL_MESSAGE = 'There is no panel for the selected filter',
}

const DEFAULT_CARD_DESCRIPTIONS = {
  descriptionAvgFirstLine: 'average',
  descriptionAvg: 'per member',
  totalFirstLine: 'total over',
  renderDescriptionTotal: (value: number) => `${value} members`,
  hoverMessage: 'vs same cohort in same clinic',
};

const metricsStaticData: MetricStaticData[] = [
  {
    key: DashboardMetrics.PANEL_SIZE,
    cardData: {
      type: 'Panel',
      title: 'Your Panel',
      subtitle: `Since ${getDateFromNDaysAgo(30, 'MMM Do, YYYY')}`,
      ...DEFAULT_CARD_DESCRIPTIONS,
      descriptionAvg: 'members',
      hoverMessage: '',
    },
  },
  {
    key: DashboardMetrics.APPOINTMENT_COUNT,
    cardData: {
      type: 'Appointment',
      title: 'Appointments',
      subtitle: 'Last 30 Days',
      ...DEFAULT_CARD_DESCRIPTIONS,
      hoverMessage: 'vs 30 days',
    },
  },
  {
    key: DashboardMetrics.CHAT_COUNT,
    cardData: {
      type: 'Chat',
      title: 'Chat messages sent',
      subtitle: 'Last 30 Days',
      ...DEFAULT_CARD_DESCRIPTIONS,
    },
  },
  {
    key: DashboardMetrics.FOOD_COMMENT,
    cardData: {
      type: 'Food',
      title: 'Food Comments sent',
      subtitle: 'Last 30 Days',
      ...DEFAULT_CARD_DESCRIPTIONS,
    },
  },
  {
    key: DashboardMetrics.WEIGHT_LOSS,
    cardData: {
      type: 'Weight',
      title: 'Weight Loss',
      subtitle: 'Since program start',
      ...DEFAULT_CARD_DESCRIPTIONS,
      descriptionAvgFirstLine: 'lbs average',
      descriptionAvg: 'per member',
      totalFirstLine: 'lbs total',
      renderDescriptionTotal: (value: number) => `over ${value} members`,
    },
  },
];

const defaultRawEmptyData: MetricRawValue = {
  self: null,
  comparisonAverage: null,
  comparisonTotal: null,
};

const defaultMetricValue: MetricValue = {
  values: defaultRawEmptyData,
  isLoading: true,
};

interface MetricsProps {
  metricsToShow?: DashboardMetrics[];
  match?: unknown;
  location?: unknown;
}

type MetricsHashValues = {
  [key in DashboardMetrics]: MetricValue;
};

const parseFilters = (rawFilters: DashboardFilters): DashboardFilters => {
  return {
    clinics: rawFilters.clinics,
    programs: rawFilters.programs.filter((option) => option.active),
    panels: rawFilters.panels.filter((option) => option.active),
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Metrics: FC<MetricsProps> = ({ metricsToShow, match: _, location: __ }) => {
  const { userState } = useContext(UserContext);
  const [metricValues, setMetricValues] = useState<MetricsHashValues | null>(null);
  const [filteredCard, setFilteredCards] = useState<MetricStaticData[]>(metricsStaticData);
  const [filters, setFilters] = useState<DashboardFilters | null>(null);

  const getDashboardFilters = useCallback(async () => {
    const dashboardServiceClient = new DashboardService();
    const filtersData = await dashboardServiceClient.getFilters();
    if (!filtersData) {
      Toast.show('error', 'Problems getting the filters. Please contact to support');
      return;
    }
    setFilters(parseFilters(filtersData));
  }, []);

  const setMetricsToDisplay = useCallback(() => {
    if (!metricsToShow) {
      setMetricValues({
        [DashboardMetrics.PANEL_SIZE]: defaultMetricValue,
        [DashboardMetrics.APPOINTMENT_COUNT]: defaultMetricValue,
        [DashboardMetrics.CHAT_COUNT]: defaultMetricValue,
        [DashboardMetrics.FOOD_COMMENT]: defaultMetricValue,
        [DashboardMetrics.WEIGHT_LOSS]: defaultMetricValue,
      });

      return;
    }

    const toSearch = metricsToShow?.concat([DashboardMetrics.PANEL_SIZE]);
    const keys = toSearch?.map((metric) => metric.toString());

    const filtered = metricsStaticData.filter((metric) => keys?.includes(metric.key));
    setFilteredCards(filtered);

    for (const metric of metricsToShow) {
      setMetricValues((prevState) => {
        return {
          ...((prevState as MetricsHashValues) || {}),
          [metric as DashboardMetrics]: {
            ...(prevState?.[metric as DashboardMetrics] || {}),
            defaultMetricValue,
          },
        };
      });
    }

    setMetricValues((prevState) => {
      return {
        ...((prevState as MetricsHashValues) || {}),
        [DashboardMetrics.PANEL_SIZE]: defaultMetricValue,
      };
    });
  }, []);

  useEffect(() => {
    getDashboardFilters();
  }, [getDashboardFilters]);

  useEffect(() => {
    setMetricsToDisplay();
  }, [setMetricsToDisplay]);

  const [canDisplayMetrics, setCanDisplayMetrics] = useState<{
    state: MetricLoadState;
    error: ErrorMessages | null;
  }>({
    state: MetricLoadState.InProgress,
    error: ErrorMessages.NO_FILTERS_MESSAGE,
  });

  const dashboardServiceClient = useMemo(() => new DashboardService(), []);

  const getMetricsData = async (providerId: number, options: DashboardMetricValuesQueryParams) => {
    const metricResults = await Promise.all(
      filteredCard.map(async ({ key, cardData: { title } }) => {
        // This metrics value update is to show the loading icon per each card
        setMetricValues((prevState) => {
          return {
            ...((prevState as MetricsHashValues) || {}),
            [key]: { ...((prevState as MetricsHashValues) || {})[key], isLoading: true },
          };
        });

        const rawData: MetricRawValue = {
          self: null,
          comparisonAverage: null,
          comparisonTotal: null,
        };
        let error: string | null = null;

        try {
          const metricData = await dashboardServiceClient.getMetricValue(providerId, key, options);

          rawData.self = metricData.entry.self;
          rawData.comparisonAverage = metricData.comparisonAverageByMember;
          rawData.comparisonTotal = metricData.comparisonTotal;
        } catch (err) {
          error = `Couldn't get data related to metric: ${title}`;
        }

        setMetricValues((prevState) => {
          return {
            ...((prevState as MetricsHashValues) || {}),
            [key]: {
              ...((prevState as MetricsHashValues) || {})[key],
              values: rawData,
              isLoading: false,
            },
          };
        });

        return { rawData, error };
      })
    );

    // Always check PANEL_SIZE first
    const panelSizeMetricIndex = metricsStaticData.findIndex(
      ({ key }) => key === DashboardMetrics.PANEL_SIZE
    );
    const panelSizeMetricResult = metricResults[panelSizeMetricIndex];

    // Show toast
    metricResults.forEach(({ rawData, error }) => {
      if (rawData.self === null) {
        // Only show toast if panel size metric was found
        if (panelSizeMetricResult.rawData.self !== null) {
          Toast.show('error', error);
        }
      }
    });

    return metricResults;
  };

  const getMetricValues = useCallback(getMetricsData, [dashboardServiceClient]);

  const handleSearch = useCallback(
    async (panelId: number, programId: number, clinicId: number) => {
      if (!filters || !userState?.id) {
        return;
      }

      const dashboardProgramSlug =
        filters?.programs.find((p) => Number(p.id) === Number(programId))?.name || 'all';
      const dashboardStatusSlug =
        filters?.panels.find((p) => Number(p.id) === Number(panelId))?.name || 'all';

      const result = await getMetricValues(userState?.id, {
        clinicId,
        dashboardProgramSlug,
        dashboardStatusSlug,
        date: new Date().toISOString().substring(0, 10),
      });

      if (!clinicId || !programId || !panelId) {
        setCanDisplayMetrics({
          state: MetricLoadState.Fail,
          error: ErrorMessages.NO_FILTERS_MESSAGE,
        });
        return;
      }

      if (!result) {
        setCanDisplayMetrics({
          state: MetricLoadState.Fail,
          error: ErrorMessages.NO_PANEL_MESSAGE,
        });
        return;
      }

      setCanDisplayMetrics({ state: MetricLoadState.Ready, error: null });
    },
    [userState?.id, filters, getMetricValues] // metricValues produce a loop
  );

  return (
    <DashboardContainer>
      <MetricsFilter
        filters={filters}
        onSearch={handleSearch}
        membersAmount={
          (metricValues && metricValues[DashboardMetrics.PANEL_SIZE].values.self?.memberCount) || 0
        }
        showCompleteViewLink={!!metricsToShow}
      />
      <MetricsContainer>
        {canDisplayMetrics.state === MetricLoadState.Ready ? (
          filteredCard
            .filter((metric) => metric.key !== DashboardMetrics.PANEL_SIZE)
            .map(({ key, cardData }) => (
              <MetricCardElement
                key={key}
                cardData={cardData}
                rawData={(metricValues && metricValues[key].values) || defaultRawEmptyData}
                isLoading={(metricValues && metricValues[key].isLoading) || false}
                membersAmount={
                  (metricValues &&
                    metricValues[DashboardMetrics.PANEL_SIZE].values.self?.memberCount) ||
                  0
                }
              />
            ))
        ) : (
          <NoMetrics>{canDisplayMetrics.error}</NoMetrics>
        )}
      </MetricsContainer>
    </DashboardContainer>
  );
};

export default Metrics;

import momentTimezone from 'moment-timezone';

type AppointmentTypeCodes = ['FU' | 'TR', 'NS' | 'MD' | 'ES', 'OL' | 'IP', string];

const appointmentHasAppointmentTypeCode = (appointmentType: string) =>
  appointmentType.includes('[') && appointmentType.includes(']');

export const formatAppointmentStartEnd = (
  appointment: { startAt: string; endAt: string },
  timezone: string
) => {
  const start = momentTimezone(appointment.startAt).tz(timezone).format('h:mm A');
  const endAndTimezone = momentTimezone(appointment.endAt).tz(timezone).format('h:mm A z');
  const timesAsText = `${start} – ${endAndTimezone}`;
  return timesAsText;
};

export const getAppointmentTypeCodes = (
  appointmentType?: string | null
): AppointmentTypeCodes | [] => {
  if (!appointmentType || !appointmentHasAppointmentTypeCode(appointmentType)) {
    return [];
  }

  return appointmentType
    .toUpperCase()
    .split('[')[1]
    .split(']')[0]
    .split('-') as AppointmentTypeCodes;
};
export const getAppointmentName = (appointmentType: string): string =>
  appointmentType?.split('[')[0] || '';

export const getAppointmentSimplifiedName = (appointmentType: string): string =>
  getAppointmentName(appointmentType).replaceAll('Appointment', '').replaceAll('  ', ' ').trim() ||
  '';

/* eslint-disable jsx-a11y/media-has-caption */
import DownloadIcon from '@mui/icons-material/Download';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, Button, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import './audio-player.css';

interface Props {
  currentLineTime: number | null;
  audioSource: string;
  onUpdatePlayerStatus?: ({
    currentTime,
    isPlaying,
  }: {
    currentTime: number;
    isPlaying: boolean;
  }) => void;
}

const AudioPlayer = ({ currentLineTime, audioSource, onUpdatePlayerStatus }: Props) => {
  // State
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [currentSpeed, setCurrentSpeed] = useState<number>(1);

  // References
  const audioPlayerRef = useRef<HTMLAudioElement | null>(null); // reference our audio component
  const progressBarRef = useRef<HTMLInputElement | null>(null); // reference our progress bar
  const animationRef = useRef<number>(0); // reference the animation

  const calculateTime = (time: number) => {
    const seconds = Math.floor(time);
    const restSeconds = seconds % 60;
    const minutes = Math.floor(seconds / 60);

    const restMinutes = minutes % 60;
    const hours = Math.floor(minutes / 60);
    const baseTimeItems: number[] = [];

    if (hours > 0) {
      baseTimeItems.push(hours);
    }

    baseTimeItems.push(restMinutes);
    baseTimeItems.push(restSeconds);

    return baseTimeItems
      .map((t) => {
        if (t < 10) {
          return `0${t}`;
        }

        return `${t}`;
      })
      .join(':');
  };

  const changePlayerCurrentTime = () => {
    if (progressBarRef.current) {
      const newCurrentTime = Number(progressBarRef.current.value);

      if (duration) {
        progressBarRef.current.style.setProperty(
          '--seek-before-width',
          `${(newCurrentTime / duration) * 100}%`
        );
      }

      setCurrentTime(newCurrentTime);
    }
  };

  const whilePlaying = () => {
    if (progressBarRef.current && audioPlayerRef.current) {
      progressBarRef.current.value = audioPlayerRef.current.currentTime.toString();
      changePlayerCurrentTime();
      animationRef.current = requestAnimationFrame(whilePlaying);
    }
  };

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);

    if (!prevValue && audioPlayerRef.current) {
      audioPlayerRef.current.play();
      audioPlayerRef.current.playbackRate = currentSpeed;
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayerRef.current?.pause();
      cancelAnimationFrame(animationRef.current);
    }
  };

  const changeRange = () => {
    if (progressBarRef.current && audioPlayerRef.current) {
      audioPlayerRef.current.currentTime = Number(progressBarRef.current.value);
      changePlayerCurrentTime();
    }
  };

  const backThirty = () => {
    if (progressBarRef.current) {
      progressBarRef.current.value = (Number(progressBarRef.current.value) - 30).toString();
      changeRange();
    }
  };

  const forwardThirty = () => {
    if (progressBarRef.current) {
      progressBarRef.current.value = (Number(progressBarRef.current.value) + 30).toString();
      changeRange();
    }
  };

  const setTime = (time: number) => {
    if (progressBarRef.current) {
      progressBarRef.current.value = Number(time).toString();
      changeRange();
    }
  };

  useEffect(() => {
    const seconds = Math.floor(audioPlayerRef.current?.duration || 0);
    setDuration(seconds);

    if (seconds === 0) {
      return;
    }

    if (progressBarRef.current) {
      progressBarRef.current.max = seconds.toString();
    }
  }, [
    audioPlayerRef.current?.onloadedmetadata,
    audioPlayerRef.current?.onloadeddata,
    audioPlayerRef.current?.readyState,
  ]);

  useEffect(() => {
    if (!onUpdatePlayerStatus) {
      return;
    }

    onUpdatePlayerStatus({ currentTime, isPlaying });
  }, [currentTime, isPlaying]);

  useEffect(() => {
    if (currentLineTime === null || currentTime === currentLineTime) {
      return;
    }

    setTime(currentLineTime);
  }, [currentLineTime]);

  useEffect(() => {
    if (!audioPlayerRef || !audioPlayerRef.current) {
      return;
    }

    audioPlayerRef.current.playbackRate = currentSpeed;
  }, [currentSpeed]);

  return (
    <Box className='audio-player'>
      <audio ref={audioPlayerRef} src={audioSource} preload='metadata' />

      <Button className='forward-backward' onClick={backThirty}>
        <FastRewindIcon />
      </Button>

      <Button onClick={togglePlayPause} className='play-pause'>
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon className='play' />}
      </Button>

      <Button className='forward-backward' onClick={forwardThirty}>
        <FastForwardIcon />
      </Button>

      {/* Current time */}
      <div className='current-time'>{calculateTime(currentTime)}</div>

      {/* Progress bar */}
      <Box className='progress-bar-container'>
        <input
          type='range'
          className='progress-bar'
          defaultValue='0'
          ref={progressBarRef}
          onChange={changeRange}
        />
      </Box>

      {/* Duration */}
      <Box className='duration'>
        {duration && !Number.isNaN(duration) && calculateTime(duration)}
      </Box>

      <Box className='speed-selector'>
        <FormControl className='form-control' size='small' variant='standard'>
          <Select
            value={currentSpeed}
            className='select'
            onChange={(e: SelectChangeEvent<number>) => setCurrentSpeed(Number(e.target.value))}>
            <MenuItem value={0.5}>0.5x</MenuItem>
            <MenuItem value={1}>Normal</MenuItem>
            <MenuItem value={1.25}>1.25x</MenuItem>
            <MenuItem value={1.75}>1.75x</MenuItem>
            <MenuItem value={2}>2x</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box className='download-link'>
        <a href={audioSource} target='_blank' rel='noreferrer' download>
          <DownloadIcon />
          Download
        </a>
      </Box>
    </Box>
  );
};

export { AudioPlayer };

/* eslint-disable */
import api from 'api';
import { Button, Modal } from 'components/elements';
import Icon from 'components/icon';
import Toast from 'components/toast';
import {
  ASSIGNED_AS_GHOST_PROVIDERS,
  ASSIGNED_AS_REGULAR_PROVIDERS,
  UNREMOVABLE_PROVIDERS,
} from 'config';
import { ROLE_UNAVAILABLE, STATUS_HIDDEN } from 'globalConstants';
import useDebounce from 'hooks/useDebounce';
import { CommonEventNames } from 'lib/types';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { TrackableElement } from 'services/mixpanel/mixpanel-service';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { ClickElementTypes, EventHandlerNames } from 'services/mixpanel/mixpanel.types';
import { ConfirmationContext } from 'state/contexts/confirmation';
import { PatientContext } from 'state/contexts/patient';
import { PatientSummaryContext } from 'state/contexts/patientSummary';
import { ProviderContext } from 'state/contexts/provider';
import { ProvidersContext } from 'state/contexts/providers';
import { UserContext } from 'state/contexts/user';
import { ActionTypes as ConfirmationActionTypes } from 'state/reducers/confirmation';
import { ActionTypes as ProviderActionTypes } from 'state/reducers/provider';
import { debug } from 'utils/helpers';
import ghostIcon from '../../../icon/pngs/ghost3-32.png';
import minusIcon from '../../../icon/pngs/minus-32.png';
import minusTempIcon from '../../../icon/pngs/minus-temp-32.png';
import plusIcon from '../../../icon/pngs/plus-32.png';
import plusTempIcon from '../../../icon/pngs/plus-temp-32.png';
import primaryIcon from '../../../icon/pngs/primary000-32.png';
import notPrimaryIcon from '../../../icon/pngs/primary001-32.png';
import {
  ActionIcon,
  ActionItem,
  Actions,
  DisabledImage,
  Header,
  IconPlaceholder,
  Input,
  Item,
  Name,
  ProvidersList,
  Record,
  Role,
} from './style';

// 5 means provider already assigned but it is the primary provider
// 4 means provider already assigned but it is an unremovable provider
// 3 means provider already assigned
// 2 means provider already assigned and the user is attempting to remove it
// 0 means provider is un-assigned
// 1 means provider is un-assigned and the user is attempting to add it as visible
// 11 means provider is un-assigned and the user is attempting to add it as hidden

const PROVIDER_STATUS = {
  UNASSIGNED: 0,
  UNASSIGNED_ADDING_VISIBLE: 1,
  UNASSIGNED_ADDING_HIDDEN: 11,
  ASSIGNED_REMOVING: 2,
  ASSIGNED: 3,
  ASSIGNED_UNREMOVABLE: 4,
  ASSIGNED_PRIMARY_PROVIDER: 5,
};

const MODAL_NAME = 'Manage Providers Modal';

const ManageProvider = ({ onClose }) => {
  const { patientState } = useContext(PatientContext);
  const { providersState } = useContext(ProvidersContext);
  const { providerState, providerDispatch } = useContext(ProviderContext);
  const { userState } = useContext(UserContext);

  const { patientSummaryDispatch } = useContext(PatientSummaryContext);
  const { confirmationDispatch } = useContext(ConfirmationContext);

  const [filter, setFilter] = useState('');
  const debouncedFilter = useDebounce(filter);

  const patient = patientState.current;
  const [disable, setDisable] = useState(true);
  const [allProvidersFiltered, setAllProvidersFiltered] = useState([]);

  const getOtherAttributes = (p, roleId, memberProviders) => {
    const partnership = memberProviders.partnerships.find(
      (partnership) => partnership.provider.id === p.id && partnership.role?.id === roleId
    );

    if (partnership) {
      const result = { ...partnership };
      result.flag =
        partnership.primary === true
          ? PROVIDER_STATUS.ASSIGNED_PRIMARY_PROVIDER
          : UNREMOVABLE_PROVIDERS.includes(
              [partnership.provider.first_name, partnership.provider.last_name].join('').trim()
            ) && partnership.role
          ? PROVIDER_STATUS.ASSIGNED_UNREMOVABLE
          : PROVIDER_STATUS.ASSIGNED;
      result.primary_provider = partnership.primary === true;
      return result;
    }
    return { flag: PROVIDER_STATUS.UNASSIGNED, primary_provider: false };
  };

  const [allProviders, setAllProviders] = useState([]);

  const updateRecord = (uuid, roleId) => {
    const list = [...allProviders];
    const result = list.filter((p) => p.uuid === uuid && p.role_id === roleId);

    if (result.length === 1) {
      if (result[0].flag === PROVIDER_STATUS.ASSIGNED) {
        result[0].flag = PROVIDER_STATUS.ASSIGNED_REMOVING;
      } else if (result[0].flag === PROVIDER_STATUS.ASSIGNED_REMOVING) {
        result[0].flag = PROVIDER_STATUS.ASSIGNED;
      } else if (result[0].flag === PROVIDER_STATUS.UNASSIGNED_ADDING_HIDDEN) {
        result[0].flag = PROVIDER_STATUS.UNASSIGNED;
      } else if (result[0].flag === PROVIDER_STATUS.UNASSIGNED_ADDING_VISIBLE) {
        result[0].flag = !ASSIGNED_AS_REGULAR_PROVIDERS.includes(
          result[0].first_name + result[0].last_name
        )
          ? PROVIDER_STATUS.UNASSIGNED_ADDING_HIDDEN
          : PROVIDER_STATUS.UNASSIGNED;
      } else if (result[0].flag === PROVIDER_STATUS.UNASSIGNED) {
        result[0].flag = ASSIGNED_AS_GHOST_PROVIDERS.includes(
          result[0].first_name + result[0].last_name
        )
          ? PROVIDER_STATUS.UNASSIGNED_ADDING_HIDDEN
          : PROVIDER_STATUS.UNASSIGNED_ADDING_VISIBLE;
      }
    }
    return list;
  };

  useEffect(() => {
    const providersData = filter
      ? allProviders.filter(
          (p) => `${p.first_name} ${p.last_name}`.toLowerCase().indexOf(filter.toLowerCase()) !== -1
        )
      : allProviders;

    // at least one provider is selected
    if (
      providersData.some(
        (elem) =>
          elem.flag === PROVIDER_STATUS.UNASSIGNED_ADDING_VISIBLE ||
          elem.flag === PROVIDER_STATUS.UNASSIGNED_ADDING_HIDDEN ||
          elem.flag === PROVIDER_STATUS.ASSIGNED_REMOVING
      )
    ) {
      setDisable(false);
    } else {
      setDisable(true);
      providersData.sort(
        (a, b) =>
          b.flag - a.flag ||
          a.first_name.localeCompare(b.first_name) ||
          a.last_name.localeCompare(b.last_name)
      );
    }
    setAllProvidersFiltered(providersData);
  }, [debouncedFilter, allProviders]);

  useEffect(() => {
    const providers = [];
    const assignedProvidersWithoutRole = providerState.partnerships.filter((p) => !p.role);

    for (let p of providersState.data) {
      // Add provider if he is assigned without any role
      if (
        assignedProvidersWithoutRole.find(
          (assignedProviderWithoutRole) => assignedProviderWithoutRole.provider.id === p.id
        )
      ) {
        providers.push({
          ...p,
          role_id: undefined,
          role_name: ROLE_UNAVAILABLE,
          ...getOtherAttributes(p, undefined, providerState),
        });
      }

      // Duplicate provider based on roles
      for (let role of p.roles)
        providers.push({
          ...p,
          role_id: role.id,
          role_name: role.display_value || role.name,
          ...getOtherAttributes(p, role.id, providerState),
        });
    }
    setAllProviders(providers);
  }, [providerState]);

  const change = (event) => {
    const providerUuid = event.target.getAttribute('data-p-uuid');
    const providerRoleId = isNaN(event.target.getAttribute('data-p-role-id'))
      ? undefined
      : +event.target.getAttribute('data-p-role-id');
    setAllProviders(updateRecord(providerUuid, providerRoleId));
  };

  const setPrimaryProviderSubmit = async (provider_uuid, provider_role_id) => {
    try {
      const { data: resp } = await api().post(`/patients/set_primary_provider`, {
        auth_token: 'AUTH_TOKEN',
        patient_uuid: patient.uuid,
        provider_uuid,
        role_id: provider_role_id,
      });

      if (resp.success === 'true') {
        // update providerState (patient providers)
        getProviders();
      } else {
        // Toast.show('error', `Couldn't change the primary provider, please try again later!`);
      }
    } catch (err) {
      // Toast.show('error', `Couldn't change the primary provider, please try again later!`);
      debug(err);
    }
  };

  const setPrimaryProviderOnYes = async (closeConfirmation, id, callBack, confirmationObject) => {
    // setDisable(true);
    closeConfirmation(); // close the confirmation modal

    await setPrimaryProviderSubmit(id, confirmationObject.role_id);
  };

  const setPrimaryProviderOnNo = async (closeConfirmation) => {
    closeConfirmation();
  };

  const setPrimaryProvider = async (event) => {
    const providerUuid = event.target.getAttribute('data-p-uuid');
    const providerRoleId = +event.target.getAttribute('data-p-role-id');
    const providerName = event.target.getAttribute('data-p-name');

    confirmationDispatch({
      type: ConfirmationActionTypes.SHOW,
      payload: {
        onYes: setPrimaryProviderOnYes,
        onNo: setPrimaryProviderOnNo,
        id: providerUuid,
        role_id: providerRoleId,
        message: `Are you sure you want to change the primary provider for this patient to <b>${providerName}</b>?`,
      },
    });
  };

  const getProviders = async () => {
    if (patient) {
      try {
        const { data } = await api().get(`users/${patient.id}/providers/AUTH_TOKEN`);
        providerDispatch({
          type: ProviderActionTypes.SET,
          payload: {
            providers: data,
          },
        });
      } catch (err) {
        debug(err);
      }
    }
  };

  const submit = async () => {
    const toRemove = [];
    let removedCount = 0;
    const notRemoved = [];
    const toAdd = [];
    let addedCount = 0;
    const notAdded = [];
    for (const p of allProviders) {
      if (p.flag === PROVIDER_STATUS.ASSIGNED_REMOVING) {
        toRemove.push({ uuid: p.uuid, role_id: p.role_id, name: `${p.first_name} ${p.last_name}` });
      } else if (p.flag === PROVIDER_STATUS.UNASSIGNED_ADDING_VISIBLE) {
        toAdd.push({ uuid: p.uuid, role_id: p.role_id, name: `${p.first_name} ${p.last_name}` });
      } else if (p.flag === PROVIDER_STATUS.UNASSIGNED_ADDING_HIDDEN) {
        toAdd.push({
          uuid: p.uuid,
          role_id: p.role_id,
          name: `${p.first_name} ${p.last_name}`,
          hidden: true,
        });
      }
    }

    for (const provider of toRemove) {
      try {
        const { data: remResp } = await api().post(`/patients/delete_provider`, {
          auth_token: 'AUTH_TOKEN',
          patient_uuid: patient.uuid,
          provider_uuid: provider.uuid,
          role_id: provider.role_id,
        });

        if (remResp.success === 'true') {
          removedCount++;
        } else {
          notRemoved.push(provider);
        }
      } catch (err) {
        debug(err);
        notRemoved.push(provider);
      }
    }
    for (const provider of toAdd) {
      try {
        const { data: addResp } = await api().post(
          `/patients/${provider.hidden === true ? 'add_hidden_provider' : 'add_provider'}`,
          {
            auth_token: 'AUTH_TOKEN',
            patient_uuid: patient.uuid,
            provider_uuid: provider.uuid,
            role_id: provider.role_id,
          }
        );

        if (addResp.success === 'true') {
          addedCount++;
        } else {
          notAdded.push(provider);
        }
      } catch (err) {
        debug(err);
        notAdded.push(provider);
      }
    }
    if (notAdded.length || notRemoved.length) {
      Toast.show(
        'error',
        `Couldn't add/remove the provider(s):\
      ${[...notAdded, ...notRemoved].map((elem) => elem.name).join(', ')}`
      );
    }

    MixpanelWrapperInstance.trackClick({
      customEventName: CommonEventNames.ROLE_ASSIGNMENT,
      name: 'Yes',
      type: ClickElementTypes.BUTTON,
      source: `${MODAL_NAME} - Confirmation`,
      inputData: {
        assign: toAdd,
        unassigned: toRemove,
      },
    });

    if (addedCount > 0 || removedCount > 0) {
      patientSummaryDispatch({ type: 'RELOAD' });
    }

    // update providerState (patient providers)
    getProviders();
    return onClose();
  };

  //Partially done
  const getConfirmationMessage = () => {
    const toRemove = [];
    const toAdd = [];
    let currentProviderFutureAssignments = 0;
    for (const p of allProviders) {
      switch (p.flag) {
        case PROVIDER_STATUS.ASSIGNED_REMOVING:
          toRemove.push(
            `<br> - ${p.first_name} ${p.last_name} (${p.role_name})${
              p.status === STATUS_HIDDEN ? ' <i>(Hidden)</i>' : ''
            }`
          );
          break;

        case PROVIDER_STATUS.UNASSIGNED_ADDING_VISIBLE:
          if (userState.uuid === p.uuid) {
            currentProviderFutureAssignments++;
          }
          toAdd.push(`<br> + ${p.first_name} ${p.last_name} (${p.role_name})`);
          break;

        case PROVIDER_STATUS.UNASSIGNED_ADDING_HIDDEN:
          if (userState.uuid === p.uuid) {
            currentProviderFutureAssignments++;
          }
          toAdd.push(`<br> + ${p.first_name} ${p.last_name} (${p.role_name}) <i>(Hidden)</i>`);
          break;

        case PROVIDER_STATUS.ASSIGNED:
        case PROVIDER_STATUS.ASSIGNED_UNREMOVABLE:
        case PROVIDER_STATUS.ASSIGNED_PRIMARY_PROVIDER:
          if (userState.uuid === p.uuid) {
            currentProviderFutureAssignments++;
          }
          break;
      }
    }

    let providersToAddHtml = '';
    let providersToRemoveHtml = '';

    if (toAdd.length) {
      providersToAddHtml = '<b>Provider(s) to be added:</b>';
      for (const p of toAdd) {
        providersToAddHtml += p;
      }
      providersToAddHtml += '<br>';
    }
    if (toRemove.length) {
      providersToRemoveHtml = `${toAdd.length ? '<br>' : ''}<b>Provider(s) to be removed:</b> ${
        currentProviderFutureAssignments <= 0
          ? ' <b style="font-size: larger; top: 3px; position: relative;">*</b>'
          : ''
      }`;
      for (const p of toRemove) {
        providersToRemoveHtml += p;
      }
      providersToRemoveHtml += '<br>';
    }
    const asterisk =
      '<br><span style="font-size: x-large; top: 6px; position: relative;">*</span><span style="font-size: small; font-style: italic;"> Removing yourself from the patient profile will prevent you from accessing it in the future!</span><br>';
    const question = '<br><b>Are you sure you want to proceed?</b>';
    const result = {
      reload: currentProviderFutureAssignments <= 0,
      message:
        providersToAddHtml +
        providersToRemoveHtml +
        (currentProviderFutureAssignments <= 0 ? asterisk : '') +
        question,
    };
    return result;
  };

  const onYes = async (closeConfirmation, id, callBack) => {
    setDisable(true);
    closeConfirmation(); // close the confirmation modal
    await submit();

    callBack();
  };

  const onNo = async (closeConfirmation, id) => {
    closeConfirmation();
  };

  const confirmSubmit = () => {
    const { reload, message } = getConfirmationMessage();
    confirmationDispatch({
      type: ConfirmationActionTypes.SHOW,
      payload: {
        onYes,
        onNo,
        message,
        callBack: () => {
          if (reload) window.open(`/`, '_self');
        },
      },
    });
  };

  return (
    <Modal onClose={onClose}>
      <Header>
        <h2>Manage Providers</h2>
        <p>{`These are the providers working with ${patient.first_name} ${patient.last_name}`}</p>
      </Header>
      <Input
        type='text'
        value={filter}
        placeholder={`Search ${allProviders.length} Providers`}
        onChange={(e) => setFilter(e.target.value)}
      />
      <ProvidersList>
        {allProvidersFiltered.map((p, index) => (
          <div key={`m_p-${p.uuid}-role-${p.role_id}`}>
            <Record key={`manage_provider-${p.uuid}-role-${p.role_id}`}>
              <Item>
                {p.avatar ? (
                  <img src={p.avatar} alt='img' />
                ) : (
                  <Icon icon='userBubble' color='turquoise' size={42} />
                )}
              </Item>

              <Name flag={p.flag} pStatus={PROVIDER_STATUS}>
                <div>
                  <span>
                    {p.first_name} {p.last_name}
                  </span>
                </div>
                <Role>
                  <span>{p.role_name}</span>
                </Role>
              </Name>
              <ActionItem>
                {/* -------- primary provider toggle ---------- */}
                {p.primary_provider === true ? (
                  <div
                    data-tip='Primary Provider'
                    data-for={`primary-provider-${p.uuid}-role-${p.role_id}`}
                    key={`primary-provider-${p.uuid}-role-${p.role_id}`}>
                    <ReactTooltip
                      id={`primary-provider-${p.uuid}-role-${p.role_id}`}
                      place='top'
                      type='dark'
                      effect='solid'
                    />
                    <img
                      id={`primaryprovider_${index}_${p.uuid}_${p.role_id}`}
                      src={primaryIcon}
                      alt=''
                    />
                  </div>
                ) : !(p.status === STATUS_HIDDEN) &&
                  p.role_id &&
                  (p.flag === PROVIDER_STATUS.ASSIGNED_UNREMOVABLE ||
                    p.flag === PROVIDER_STATUS.ASSIGNED) ? (
                  <ActionIcon
                    data-tip='Set as Primary Provider'
                    data-for={`set-primary-provider-${p.uuid}-role-${p.role_id}`}
                    key={`set-primary-provider-${p.uuid}-role-${p.role_id}`}
                    onClick={setPrimaryProvider}>
                    <ReactTooltip
                      id={`set-primary-provider-${p.uuid}-role-${p.role_id}`}
                      place='top'
                      type='dark'
                      effect='solid'
                    />
                    <img
                      id={`primaryprovider_${index}_${p.uuid}_${p.role_id}`}
                      data-p-uuid={`${p.uuid}`}
                      data-p-role-id={`${p.role_id}`}
                      data-p-name={`${p.first_name} ${p.last_name}`}
                      src={notPrimaryIcon}
                      alt=''
                    />
                  </ActionIcon>
                ) : (
                  <IconPlaceholder />
                )}

                {/* -------------- Ghost ----------------*/}
                {p.status === STATUS_HIDDEN ||
                p.flag === PROVIDER_STATUS.UNASSIGNED_ADDING_HIDDEN ? (
                  <ActionIcon
                    data-tip='Hidden Provider'
                    data-for={`hidden-${p.uuid}-role-${p.role_id}`}
                    key={`hidden-${p.uuid}-role-${p.role_id}`}
                    // onClick={setPrimaryProvider}
                  >
                    <ReactTooltip
                      id={`hidden-${p.uuid}-role-${p.role_id}`}
                      place='top'
                      type='dark'
                      effect='solid'
                    />
                    <DisabledImage
                      id={`hidden_${index}_${p.uuid}`}
                      data-p-uuid={`${p.uuid}`}
                      data-p-role-id={`${p.role_id}`}
                      data-p-name={`${p.first_name} ${p.last_name}`}
                      src={ghostIcon}
                      alt=''
                    />
                  </ActionIcon>
                ) : (
                  <IconPlaceholder />
                )}

                {/* --------------- add remove ----------- */}
                {p.flag !== PROVIDER_STATUS.ASSIGNED_PRIMARY_PROVIDER &&
                p.flag !== PROVIDER_STATUS.ASSIGNED_UNREMOVABLE ? (
                  <ActionIcon
                    data-tip={`${
                      p.flag === PROVIDER_STATUS.ASSIGNED
                        ? 'Remove'
                        : p.flag === PROVIDER_STATUS.UNASSIGNED
                        ? 'Add'
                        : p.flag === PROVIDER_STATUS.ASSIGNED_REMOVING
                        ? 'Undo Remove'
                        : p.flag === PROVIDER_STATUS.UNASSIGNED_ADDING_VISIBLE
                        ? 'Add hidden'
                        : 'Undo Add'
                    } Provider`}
                    data-for={`manage-provider-${p.uuid}-role-${p.role_id}`}
                    key={`manage-provider-${p.uuid}-role-${p.role_id}`}
                    onClick={change}>
                    <ReactTooltip
                      id={`manage-provider-${p.uuid}-role-${p.role_id}`}
                      place='top'
                      type='dark'
                      effect='solid'
                    />
                    <img
                      id={`provider_${index}_${p.uuid}_${p.role_id}`}
                      data-p-uuid={`${p.uuid}`}
                      data-p-role-id={`${p.role_id}`}
                      data-p-name={`${p.first_name} ${p.last_name}`}
                      src={
                        p.flag === PROVIDER_STATUS.ASSIGNED
                          ? minusIcon
                          : p.flag === PROVIDER_STATUS.UNASSIGNED
                          ? plusIcon
                          : p.flag === PROVIDER_STATUS.ASSIGNED_REMOVING
                          ? minusTempIcon
                          : plusTempIcon
                      }
                      alt=''
                    />
                  </ActionIcon>
                ) : (
                  <IconPlaceholder />
                )}
              </ActionItem>
            </Record>
          </div>
        ))}
      </ProvidersList>
      <Actions>
        <TrackableElement
          customEventName={CommonEventNames.ROLE_ASSIGNMENT}
          name='Cancel'
          type={ClickElementTypes.BUTTON}
          source={MODAL_NAME}
          eventHandlerName={EventHandlerNames.ONCLICK}>
          <Button variant='link' onClick={onClose}>
            Cancel
          </Button>
        </TrackableElement>
        <TrackableElement
          customEventName={CommonEventNames.ROLE_ASSIGNMENT}
          name='Update Providers'
          type={ClickElementTypes.BUTTON}
          source={MODAL_NAME}
          eventHandlerName={EventHandlerNames.ONCLICK}>
          <Button onClick={confirmSubmit} disabled={disable}>
            Update Providers
          </Button>
        </TrackableElement>
      </Actions>
    </Modal>
  );
};

ManageProvider.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ManageProvider;

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Moment } from 'moment';
import moment from 'moment-timezone';

const MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000;

export const DateFormats = {
  STANDARD_FORMATTING_FOR_RAW_DATES_FORMAT: 'YYYY-MM-DD HH:mm:ss Z',
  DATE_FORMAT: 'YYYY-MM-DD',
  SHORT_DATETIME_FORMAT: 'YYYY-MM-DD hh:mm',
  SHORT_DATETIME_AM_PM_FORMAT: 'MM/DD/YY, hh:mm a',
  FIRST_DAY_FORMAT: 'YYYY-MM-01',
  DATETIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  DATETIME_NO_SECONDS_FORMAT: 'YYYY-MM-DD HH:mm',
  MM_DD_YYYY_FORMAT: 'MM-DD-YYYY',
  MONTH_FORMAT: 'YYYY-MM',
  MONTH_DAY_FORMAT: 'MM-DD',
  MMM_DAY_FORMAT: 'MMM DD',
  FULL_DATE_WITH_TIMEZONE_FORMAT: 'dddd, MMMM DD [at] hh:mm A ([GMT]Z)',
  OFFSET_FORMAT: '[GMT]Z',
  DAY_NAME_TIME: 'dddd h:mm A',
  TIME_WITH_TIMEZONE_FORMAT: 'hh:mm A',
  TIME_WITH_ABBREVIATED_TIMEZONE_FORMAT: 'hh:mm A z',
  TIMEZONE_FORMAT: 'zz',
  TIMEZONE_ABBREVIATED_FORMAT: 'z',
  MONTH_NAME_YEAR_FORMAT: 'MMMM YYYY',
};

export const daysDiff = (date: MaterialUiPickersDate) => {
  return Math.round((moment(date) - moment()) / MILLISECONDS_IN_DAY);
};

export const displayTime = (timeWithOffset: string, timezone: string) => {
  const date = moment.tz(timeWithOffset, timezone);
  return date.format(DateFormats.TIME_WITH_ABBREVIATED_TIMEZONE_FORMAT);
};

export const displayDateTimeWithOffset = (datetimeWithOffset: string) => {
  const dateTime = moment.parseZone(datetimeWithOffset);
  return dateTime.format(DateFormats.FULL_DATE_WITH_TIMEZONE_FORMAT);
};

export const humanReadableDate = (daysFromToday: number) => {
  switch (daysFromToday) {
    case 0:
      return `today ${moment().format(DateFormats.MONTH_DAY_FORMAT)}`;
    case 1:
      return `tomorrow ${moment().add(1, 'days').format(DateFormats.MONTH_DAY_FORMAT)}`;
    default:
      return `${moment().add(daysFromToday, 'days').format(DateFormats.MM_DD_YYYY_FORMAT)}`;
  }
};

export const getAge = (dateString?: string): string => {
  if (!dateString) {
    return '';
  }

  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return `${age}`;
};

/**
 *
 * @param {Date} date
 * @returns {{ start: string, end: string}} - start is previous month, format: 'YYYY-MM-22' and end is next month, format 'YYYY-MM-07'
 */
export const getSearchDateRange = (date: Date) => {
  const end = moment(date).add(1, 'month').format('YYYY-MM-07');
  const start = moment(date).subtract(1, 'month').format('YYYY-MM-22');
  const obj = { start, end };
  return obj;
};

export const getTimeWithTimezoneAsString = (date: Moment) => {
  /**
   * Here we're using Moment instead of Date, since Date objects don't handle timezones
   */
  return date.format('LT (z)');
};

export const fillSeriesWithZerosForMissingDates = (
  initialSeries: { date: string; value: number }[],
  format: string
) => {
  const series: { date: string; value: number }[] = [];
  initialSeries.forEach((item, index) => {
    if (index !== 0) {
      let nextDayDate = moment(series[series.length - 1].date, format).add(1, 'days');
      while (nextDayDate < moment(item.date, format)) {
        // it'll fill missing entries with 0s until it finds the next date in initialSeries
        series.push({ date: nextDayDate.format(format), value: 0 });
        nextDayDate = moment(nextDayDate, format).add(1, 'days');
      }
    }
    series.push(item);
  });
  return series;
};

export const getDateTimeFromString = (date: string) => moment(date).format('MM/DD/YY h:mm:ss a');

export const getDateWithUTCFormatFromString = (date: string) => moment(date).tz('UTC');

export const getDateFromNMonthsAgo = (totalMonths: number) =>
  moment().subtract(totalMonths, 'M').format('YYYY-MM-DD');

export const getDateFromNMonthsLater = (totalMonths: number) =>
  moment().add(totalMonths, 'M').format('YYYY-MM-DD');

export const getDateFromNDaysAgo = (days: number, format = 'YYYY-MM-DD') =>
  moment().subtract(days, 'd').format(format);

export const getFormattedDateFromISOString = (date: string, format: string) =>
  moment(date).local().format(format);

export const getDateFromMillis = (dateInMs: number) => moment(dateInMs).toISOString();

export const replaceUtcStringFromDateByZeroOffset = (sourceDate: string): string =>
  sourceDate.replace('UTC', '+0000');

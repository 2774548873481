import { scribeClient } from './scribe-client';
import { BriefSummary, EntityWithSummaryInfo } from './scribe.types';

export const fillEntityOutWithSummaryInfo = async <T extends { id: number }>(
  entities: T[]
): Promise<EntityWithSummaryInfo<T>[]> => {
  const briefSummaries = await scribeClient.getBriefSummariesByFilters(entities.map((e) => e.id));

  if (briefSummaries) {
    const briefSummariesArray = Object.entries(briefSummaries).reduce(
      (prev, entry) => [...prev, ...entry[1]],
      [] as BriefSummary[]
    );

    return entities.map((e) => {
      const summary = briefSummariesArray.find((s) => s.externalSchedulingId === e.id);

      return {
        ...e,
        meetingStatus: summary?.status,
        meetingId: summary?.meetingId,
      };
    });
  }

  return entities;
};

import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { displayDateTimeWithOffset } from '../../../../helpers/date';
import { Container, MemberBox, ProviderBox } from './style';

interface AppointmentConfirmationProps {
  appointmentDateInProviderTimezone: string;
  appointmentDateInMemberTimezone: string;
  appointmentType: string;
  providerName: string;
  title: string;
}

const AppointmentConfirmation: FC<AppointmentConfirmationProps> = ({
  appointmentDateInProviderTimezone,
  appointmentDateInMemberTimezone,
  appointmentType,
  providerName,
  title,
}) => (
  <Container>
    <Typography variant='h6'>{title}</Typography>
    <Typography variant='body1'>{appointmentType}</Typography>
    <Typography variant='body1'>{providerName}</Typography>
    <Box>
      <ProviderBox>
        {appointmentDateInProviderTimezone && (
          <>
            <Typography variant='body1'>Date in provider&apos;s timezone:</Typography>
            <Box pl={2}>
              <Typography variant='body1'>
                {displayDateTimeWithOffset(appointmentDateInProviderTimezone)}
              </Typography>
            </Box>
          </>
        )}
      </ProviderBox>
      <MemberBox>
        {appointmentDateInMemberTimezone && (
          <>
            <Typography variant='body1'>Date in member&apos;s timezone:</Typography>
            <Box pl={2}>
              <Typography variant='body1'>
                {displayDateTimeWithOffset(appointmentDateInMemberTimezone)}
              </Typography>
            </Box>
          </>
        )}
      </MemberBox>
    </Box>
  </Container>
);

export default AppointmentConfirmation;

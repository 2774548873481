/* eslint-disable camelcase */
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { QueryBuilder } from '@mui/icons-material';
import { RadioGroup, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Button } from 'components/elements';
import Toast from 'components/toast';
import { EFeatures } from 'lib/feature/features.types';
import moment from 'moment-timezone';
import {
  AppointmentTypeWithProviders,
  DailyAvailabilityV2,
  MonthlyAvailabilityV2,
  ScheduleRestrictionRuleCode,
  User,
} from 'node-api/schedule/Appointments.types';
import AppointmentsClient from 'node-api/schedule/AppointmentsClient';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { MixpanelWrapperInstance } from 'services/mixpanel/mixpanel-wrapper';
import { EventNames } from 'services/mixpanel/provider-app-mixpanel-events';
import { ClickableElement } from 'services/mixpanel/shared-mixpanel-service';
import { FeaturesContext } from 'state/contexts/features';
import { isFromSendGridMissedAppointments } from 'utils/helpers';
import { DEFAULT_TIMEZONE } from 'utils/timezones';
import { DateFormats, displayTime } from '../../../../helpers/date';
import { AppointmentConfirmationModal } from '../AppointmentConfirmationModal';
import {
  Calendar,
  CalendarDayWrapper,
  Content,
  Hour,
  HourRadioOption,
  Hours,
  IssuesMarker,
  ScheduleActions,
  Wrapper,
} from './style';

interface ScheduleAppointmentProps {
  appointmentType: AppointmentTypeWithProviders;
  patient: {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    last_timezone: string;
  };
  provider: User;
  /** Will use Date Availability v2, including issues.
   * Endpoint only available when there's one provider configure */
  isMainProvider: boolean;
}

const IssuesDescription = {
  [ScheduleRestrictionRuleCode.WeeklyNSEs]: 'NS/ES appointment this week',
  [ScheduleRestrictionRuleCode.WeeklyGC]: 'Group Class appointment this week',
  [ScheduleRestrictionRuleCode.FollowUpMD]: 'MD appointment for this period',
  [ScheduleRestrictionRuleCode.FollowUpOther]: 'Same appointment type for this period',
  [ScheduleRestrictionRuleCode.InitialFragmentationBadSplit]: 'Slot prevents initial scheduling',
};

export const ScheduleSelectedAppointment: FC<ScheduleAppointmentProps> = ({
  appointmentType,
  patient,
  provider,
  isMainProvider,
}) => {
  const { featuresState } = useContext(FeaturesContext);
  const showScheduleWarnings = featuresState?.[EFeatures.ProviderShowScheduleWarnings];

  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [isScheduling, setIsScheduling] = useState(false);
  const [currentDate, setCurrentDate] = useState<string>(moment().format(DateFormats.DATE_FORMAT));
  const [currentMonth, setCurrentMonth] = useState<string>(
    moment().format(DateFormats.MONTH_FORMAT)
  );
  const [currentTime, setCurrentTime] = useState<string>('');
  const [currentProviderTime, setCurrentProviderTime] = useState<string>('');
  const [currentMemberTime, setCurrentMemberTime] = useState<string>('');
  const [providerTimezone, setProviderTimezone] = useState<string>();
  const [showMembersTime, setShowMembersTime] = useState<boolean>(false);

  const [availableTimes, setAvailableTimes] = useState<DailyAvailabilityV2['times']>([]);
  const [availableDays, setAvailableDays] = useState<{
    [month: string]: MonthlyAvailabilityV2['dates'];
  }>({});
  const location = useLocation();

  const formattedProviderTimezone = provider.timezone
    ? moment.tz(provider.timezone).format(DateFormats.TIMEZONE_ABBREVIATED_FORMAT)
    : providerTimezone;

  const formattedMemberTimezone = patient.last_timezone
    ? moment.tz(patient.last_timezone).format(DateFormats.TIMEZONE_ABBREVIATED_FORMAT)
    : 'Unknown timezone';

  const selectDate = (date: MaterialUiPickersDate) => {
    setCurrentDate(moment.parseZone(date).format(DateFormats.DATE_FORMAT));
  };

  const selectTime = (time: string) => {
    const memberTime = moment.tz(time, patient.last_timezone);
    const providerTime = provider.timezone
      ? memberTime.clone().tz(provider.timezone).format()
      : time;
    setCurrentTime(time);
    setCurrentMemberTime(memberTime.format());
    setCurrentProviderTime(providerTime);
  };

  const selectMonth = async (date: MaterialUiPickersDate) => {
    setCurrentMonth(moment(date).format(DateFormats.MONTH_FORMAT));
  };

  const fetchDays = useCallback(
    async (month: string) => {
      try {
        let datesWithValidation: MonthlyAvailabilityV2['dates'];

        /* Only available when there's a single provider for given role. */
        if (showScheduleWarnings && isMainProvider) {
          const { dates } = await new AppointmentsClient().getMonthlyAvailability({
            memberId: patient.id,
            month,
            appointmentTypeId: appointmentType.id,
            calendarId: provider.calendarId,
          });
          datesWithValidation = dates;
        } else {
          const {
            data: { dates },
          } = await new AppointmentsClient().getDays(
            month,
            appointmentType.id,
            provider.calendarId
          );
          if (!Array.isArray(dates)) {
            throw new Error('Invalid dates');
          }
          /* On legacy/multiple-per-role/feature-disabled, use old API
          and fill with `allowed` and without any issues */
          datesWithValidation = dates.map((date) => ({
            date: date.date,
            allowed: true,
            issues: [],
          }));
        }
        setAvailableDays((oldAvailableDays) => ({
          ...oldAvailableDays,
          [month]: datesWithValidation,
        }));
      } catch (err) {
        console.error(err);
        setAvailableDays((oldAvailableDays) => ({ ...oldAvailableDays, [month]: [] }));
      }
    },
    [appointmentType.id, provider.calendarId]
  );

  const fetchTimes = useCallback(
    async (day: string) => {
      setAvailableTimes([]);

      let slotsAvailableWithIssues: DailyAvailabilityV2['times'];
      try {
        if (showScheduleWarnings && isMainProvider) {
          const { times } = await new AppointmentsClient().getDailyAvailability({
            memberId: patient.id,
            date: day,
            appointmentTypeId: appointmentType.id,
          });
          slotsAvailableWithIssues = times;
        } else {
          const {
            data: { times },
          } = await new AppointmentsClient().getTimes(day, appointmentType.id, provider.calendarId);
          slotsAvailableWithIssues = times.map(({ time, slotsAvailable }) => ({
            allowed: true,
            time,
            slotsAvailable,
            issues: [],
          }));
        }
      } catch (err) {
        console.error(err);
        slotsAvailableWithIssues = [];
      }

      setAvailableTimes(slotsAvailableWithIssues);

      // And set provider timezone
      if (!providerTimezone && slotsAvailableWithIssues[0]?.time) {
        setProviderTimezone(
          moment.parseZone(slotsAvailableWithIssues[0]?.time).format(DateFormats.OFFSET_FORMAT)
        );
      }
    },
    [appointmentType.id, provider.calendarId, providerTimezone]
  );

  const shouldDisableDate = (date: MaterialUiPickersDate) => {
    const month = moment(date).format(DateFormats.MONTH_FORMAT);
    const dateDay = moment(date).format(DateFormats.DATE_FORMAT);
    return !availableDays[month]?.find((day) => dateDay === day.date);
  };

  const schedule = async () => {
    setIsScheduling(true);
    const fromSendGridMissedAppointment = isFromSendGridMissedAppointments(location.search);

    MixpanelWrapperInstance.track(EventNames.ScheduleClickScheduleButton, {
      'Appointment Type': appointmentType.name,
      'Appointment Type Code': appointmentType.code,
      Provider: `${provider.firstName} ${provider.lastName}`,
      'From SendGrid Missed Appointments': fromSendGridMissedAppointment,
    });

    try {
      const response = await new AppointmentsClient().createAppointment({
        appointmentTypeId: appointmentType.id,
        calendarId: provider.calendarId,
        datetime: currentTime,
        userId: patient.id,
      });
      if (response.status === 200) {
        if (fromSendGridMissedAppointment) {
          MixpanelWrapperInstance.track(EventNames.MemberProfileFromEmailClickScheduleButton, {
            targetLabel: 'schedule',
            targetType: ClickableElement.BUTTON,
          });
        }
        setShowConfirmationModal(true);
      } else {
        throw new Error('There was an error scheduling the appointment');
      }
    } catch (err) {
      console.error(err);
      Toast.show('error', `Couldn't schedule the appointment`);
    }
    setIsScheduling(false);
  };

  useEffect(() => {
    // Clean days and times when provider changes
    setAvailableDays({});
    setAvailableTimes([]);
  }, [provider]);

  useEffect(() => {
    setCurrentTime('');
    fetchTimes(currentDate);
  }, [currentDate, fetchTimes]);

  useEffect(() => {
    fetchDays(currentMonth);
  }, [currentMonth, fetchDays, provider]);

  return (
    <>
      {showConfirmationModal && (
        <AppointmentConfirmationModal
          appointmentDateInProviderTimezone={currentProviderTime}
          appointmentDateInMemberTimezone={currentMemberTime}
          appointmentType={`${appointmentType.name} ${appointmentType.code ?? ''}`}
          onClose={() => setShowConfirmationModal(false)}
          providerName={`${provider.firstName} ${provider.lastName}`}
        />
      )}
      <Wrapper>
        <Content>
          <Calendar>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                autoOk
                disablePast
                disableToolbar
                disabled
                format='yyyy-mm-dd'
                onChange={selectDate}
                onMonthChange={selectMonth}
                openTo='date'
                orientation='portrait'
                shouldDisableDate={shouldDisableDate}
                value={currentDate}
                variant='static'
                renderDay={(date, _selectedDate, _dayInCurrentMonth, nativeDayComponent) => {
                  const month = moment(date).format(DateFormats.MONTH_FORMAT);
                  const dateDay = moment(date).format(DateFormats.DATE_FORMAT);
                  const day = availableDays[month]?.find((d) => dateDay === d.date);

                  return (
                    /* We add a wrapper so we can add a relative-positioned tooltip */
                    <CalendarDayWrapper
                      data-for={`day-with-issues-tooltip-${date}`}
                      data-tip={day?.issues?.length ? IssuesDescription[day?.issues[0]] : ''}>
                      {nativeDayComponent}
                      {/* Only show Warning Issue Marker and Tooltip when enabled/issues */}
                      {showScheduleWarnings && day?.issues?.length ? (
                        <>
                          <IssuesMarker>⚠️</IssuesMarker>
                          <ReactTooltip
                            id={`day-with-issues-tooltip-${date}`}
                            place='top'
                            type='dark'
                            effect='solid'
                          />
                        </>
                      ) : null}
                    </CalendarDayWrapper>
                  );
                }}
              />
            </MuiPickersUtilsProvider>
          </Calendar>
          <Hours>
            {formattedProviderTimezone !== formattedMemberTimezone && (
              <ToggleButtonGroup size='small' sx={{ flexGrow: 0 }}>
                <ToggleButton
                  value='provider-timezone'
                  selected={!showMembersTime}
                  onClick={() => setShowMembersTime((state) => !state)}>
                  Provider ({formattedProviderTimezone})
                </ToggleButton>
                <ToggleButton
                  value='member-timezone'
                  selected={showMembersTime}
                  onClick={() => setShowMembersTime((state) => !state)}>
                  Member ({formattedMemberTimezone})
                </ToggleButton>
              </ToggleButtonGroup>
            )}

            {availableTimes.length ? (
              <RadioGroup>
                {availableTimes.map(({ allowed, issues, time }) => (
                  <HourRadioOption
                    key={`${time}-time`}
                    data-for={`${time}-time`}
                    data-tip={issues?.length ? IssuesDescription[issues[0]] : ''}
                    onClick={() => selectTime(time)}
                    selected={time === currentTime}>
                    <QueryBuilder fontSize='large' />
                    <Hour>
                      {displayTime(
                        time,
                        (showMembersTime ? patient.last_timezone : provider.timezone) ||
                          DEFAULT_TIMEZONE
                      )}
                    </Hour>
                    {showScheduleWarnings && !allowed && issues?.length ? (
                      <>
                        <IssuesMarker>⚠️</IssuesMarker>
                        <ReactTooltip id={`${time}-time`} place='top' type='dark' effect='solid' />
                      </>
                    ) : null}
                  </HourRadioOption>
                ))}
              </RadioGroup>
            ) : (
              <h5>No appointments available</h5>
            )}
          </Hours>
          <ScheduleActions>
            <Button disabled={!currentTime} onClick={schedule} submitting={isScheduling}>
              Schedule
            </Button>
          </ScheduleActions>
        </Content>
      </Wrapper>
    </>
  );
};

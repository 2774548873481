import { StyledComponentsDivProps } from 'components/general-styles';
import styled from 'styled-components';

export const DayOfWeek = styled.div`
  color: ${({ theme }) => theme.black};
  font: 13px ${({ theme }) => theme.primaryFont};
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.black};
  font: 30px ${({ theme }) => theme.primaryFont};
`;

export const Time = styled.div`
  color: ${({ theme }) => theme.black};
  font: 17px/19px ${({ theme }) => theme.primaryFont};
  text-transform: uppercase;
`;

export const Action = styled.div`
  display: flex;
  justify-content: center;
  margin: 32px 0 0;
`;

export const PatientName = styled.div<StyledComponentsDivProps>`
  cursor: pointer;
`;
